import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/';
import { InitConfigService } from './app/services/init-config.service';
import {Injector, Injectable, ReflectiveInjector} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Http, HttpModule } from '@angular/http';

declare let heapLoad: Function;

var injector = ReflectiveInjector.resolveAndCreate([InitConfigService, { provide: 'Environment', useValue: environment }]);
var initConfig = injector.get(InitConfigService);
//var appConfig = Injector.create([{provide: AppConfigService, useClass: AppConfigService, deps: [Http]]};
if (!initConfig.get()) {
  initConfig.load().then(() => {
    heapLoad(initConfig.get().haEnvironmentId);
  });
} else {
  heapLoad(initConfig.get().haEnvironmentId);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);