import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {
  onTimeChange = new Subject<any>();
  clockInterval: any;

  constructor() {
  }

  startClock() {
    this.onTimeChange.next(new Date());
    this.clockInterval = setInterval(
      (): void => this.onTimeChange.next(new Date()),
      60000);
  }

  setupClock() {
    const current = new Date();
    const remaining = (60 - current.getSeconds()) * 1000;

    setTimeout(
      () => { this.startClock(); },
      remaining);
  }

  subscribeDatetime(success, fail) {
    this.setupClock();
    this.onTimeChange.subscribe(success, fail);
  }

  stopClockInterval() {
    clearInterval(this.clockInterval);
  }
}
