import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/take';
import { DialogSupportContact } from '../../common/dialogs/support-contact-dialog/support-contact-dialog.component';
import { UserVendorOptionDialogComponent } from '../../common/dialogs/vendor-group-switching-dialog/vendor-group-switching-dialog.component';
import { TRAINING_OPTIONS } from '../../constants';
import { UserManager } from '../../managers/user-manager';
import { AppCode, APPS_WITH_SIDENAV_ARROW, EXTERNAL_APPS } from '../../models/appOption/AppCode';
import { AppOption } from '../../models/appOption/AppOption';
import { OnboardItemName } from '../../models/onboard';
import { DialogResetPassword } from '../../reset-password-dialog/dialog-reset-password';
import { AppConfigService } from '../../services/app-config.service';
import { AuthGuardService } from '../../services/auth-guard.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { BreakpointService } from '../../services/breakpoint.service';
import { DocumentService } from '../../services/document.service';
import { GlobalService } from '../../services/global.service';
import { InitConfigService } from '../../services/init-config.service';
import { LangService } from '../../services/lang.service';
import { ServerService } from '../../services/server.service';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../utilities/utils';
import { MenuMode } from '../menu/menu.component';


@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.less']
})
export class HeaderContainerComponent implements OnInit, OnDestroy {

  message: any;
  subsDashReady: Subscription;
  private sub: any;
  //menuData: any;
  chosenGroup: any;
  isSelected: string = "selected";
  isNotSelected: String = "notSelected";
  showTraining = true;
  trainingOption: any;
  showUserManagement: boolean = false;
  allowSwitchBusiness: boolean = false;
  langs = [];
  subsLangs: Subscription;
  showFrame: boolean;
  smallScreen = false;
  mediumScreen = false;
  largeScreen = true;
  menuMode = MenuMode.List;
  appsNumber = 0;
  additionalLinksNumber = 0;
  supportItems = [];

  menuHeight = '';
  menuHeightMax = '';

  userGroups: String[] = [];
  userGroup: String;
  password: String;
  isLoading = false;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() sidenavOpened: boolean = false;
  @Input() moduleSidenavOpened: boolean = false;
  @Output() sidenavHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() moduleSidenavHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() languageChangeHandler: EventEmitter<any> = new EventEmitter<any>();
  private moduleSelected = false;
  private externalApps = EXTERNAL_APPS;
  public supportContact: Object = {};
  public apps: AppOption[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authGuardService: AuthGuardService,
    private authenticateService: AuthenticateService,
    private translateService: TranslateService,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private utils: UtilsService,
    private serverService: ServerService,
    private documentService: DocumentService,
    public globalService: GlobalService,
    private langService: LangService,
    private userManager: UserManager,
    public dialog: MatDialog,
    private _logger: NGXLogger,
    private screen: BreakpointService,
    private userService: UserService,
    @Inject("Constants") private constants: any,
    @Inject('Environment') private environment: any
  ) { }

  ngOnInit() {
    this.subscribeLangs();
    this.detectScreenSize();
    this.screen.onChange.subscribe(() => this.detectScreenSize());
    this.subscribeSidenavChange();
    this.subscribeWelcomeboard();
    this.supportContact = this.utils.checkSupportAccess();

    this.appConfig.init(
      (config) => {
        this.chosenGroup = this.utils.getChosenGroup();
        this.allowSwitchBusiness = this.utils.isSsoAllowed();
        this.trainingOption = this.appConfig.get().webConfig.trainingOption;
        const webConfig = this.appConfig.get().webConfig;

        if (this.trainingOption === this.constants.TRAINING_OPTIONS.ACCORD) {
          this.documentService.getTrainingAccountExist(localStorage.getItem('username'))
            .subscribe(
              res => {
                this.showTraining = res;
                this.globalService.emitTrainingLink(this.showTraining);
              },
              err => {
                this._logger.error('error checking user');
                this.showTraining = false;
              });
        }

        if (this.utils.checkAdminAccess()) {
          this.showUserManagement = true;
        }

        if (this.utils.checkReportAccess()) {
          this.globalService.emitReportsConfig();
          this.apps.push({
            title: "REPORTS",
            code: AppCode.Reports,
            selected: this.getCurrentSelectedOption('/main/home', AppCode.Reports),
            iconPath: '../../assets/images/graph-thumbnails/svgs/landing-thumbnail-white.svg',
            url: '/main/home'
          });
        }

        if (this.utils.checkPriceNgAccess()) {
          this.apps.push({
            title: 'PRICE',
            code: AppCode.PriceNg,
            selected: this.getCurrentSelectedOption('/main/price/home', AppCode.PriceNg),
            iconPath: '../assets/images/graph-thumbnails/svgs/price-thumbnail-white.svg',
            url: '/main/price/home'
          });
        }

        if (this.utils.checkPriceAccess()) {
          this.apps.push({
            title: 'PRICE',
            code: AppCode.Price,
            selected: this.getCurrentSelectedOption('/main/price/home', AppCode.Price),
            iconPath: '../assets/images/graph-thumbnails/svgs/price-thumbnail-white.svg',
            url: webConfig.pricingDomain + '/home' + '?id_token=' + localStorage.getItem('id_token')
          });
        }

        if (this.utils.checkAAAccess()) {
          this.apps.push({
            title: "ADVANCED ANALYTICS",
            code: AppCode.AA,
            selected: false,
            iconPath: '../assets/images/graph-thumbnails/svgs/aa-thumbnail-white.svg',
            url: webConfig.aaDomain + '/dashboard/advanced-analytics' + '?id_token=' + localStorage.getItem('id_token')
          });
        }

        if (this.utils.checkCustomProductListAccess()) {
          this.apps.push({
            title: "CUSTOM PRODUCT LISTS",
            code: AppCode.CPL,
            selected: false,
            iconPath: '../assets/images/graph-thumbnails/svgs/cpl-thumbnail-white.svg',
            url: webConfig.aaDomain + '/dashboard/custom-product-list' + '?id_token=' + localStorage.getItem('id_token')
          });
        }

        if (this.utils.checkCIAAccess()) {
          this.apps.push({
            title: "CUSTOMER INSIGHTS",
            code: AppCode.CI,
            selected: false,
            iconPath: '../assets/images/graph-thumbnails/svgs/insights-thumbnail-white.svg',
            url: webConfig.ciaUrl
          });
        }

        if (this.utils.checkPromoAccess()) {
          this.apps.push({
            title: "PROMO",
            code: AppCode.Promo,
            selected: this.getCurrentSelectedOption('/main/promo/home', AppCode.Promo),
            iconPath: '../assets/images/graph-thumbnails/svgs/promotion-thumbnail-white.svg',
            url: 'main/promo/home'
          });
        }

        if (this.utils.checkInsightsPortalAccess()) {
          this.apps.push({
            title: "INSIGHTS",
            code: AppCode.Insights,
            selected: this.getCurrentSelectedOption('/main/insights-portal/categories', AppCode.Insights),
            iconPath: '../assets/images/graph-thumbnails/svgs/insights-thumbnail-white.svg',
            url: 'main/insights-portal/categories'
          });
        }

        if (this.utils.checkAssortmentAccess()) {
          this.apps.push({
            title: "ASSORTMENT",
            code: AppCode.Assort,
            selected: this.getCurrentSelectedOption('/main/assortment/home', AppCode.Assort),
            iconPath: '../assets/images/graph-thumbnails/svgs/assortment-thumbnail-white.svg',
            url: 'main/assortment/home'
          });
        }

        if (this.utils.checkDataPortalAccess()) {
          this.apps.push({
            title: "DATA PORTAL",
            code: AppCode.DP,
            selected: this.getCurrentSelectedOption('/main/data-portal/reports', AppCode.DP),
            iconPath: '../assets/images/graph-thumbnails/svgs/data-thumbnail-white.svg',
            url: 'main/data-portal/reports'
          });
        }

        if (this.utils.checkHubAccess()) {
          this.apps.push({
            title: "HUB",
            code: AppCode.Hub,
            selected: false,
            iconPath: '../assets/images/graph-thumbnails/svgs/hub-thumbnail-white.svg',
            url: this.appConfig.get().webConfig.hubJwtUrl
          });

          // get cookie on login success
          this.serverService.getCookieFromQlik(this.appConfig.get().webConfig.hubJwtUrl, {}).subscribe(
            res => {
              // do nothing
            },
            err => {
              this._logger.error(err);
              this.onHubError();
            });

        }

        this.subscribeAppChange();
        this.appsNumber = this.apps.length;
        this.additionalLinksNumber = this.environment.client === 'demo' ? ['nielsen', 'support'].length : ['support'].length;
        this.populateUserGroups();
        this.screen.setBreakpoints(this.appsNumber, this.additionalLinksNumber);
        this.setMenuHeight();
      },

      (err) => {
        this._logger.error('error initializing config');
      });
    this.emitApps();
  }

  private setMenuHeight() {
    switch (this.menuMode) {
      case MenuMode.List:
        this.menuHeightMax = ((this.appsNumber + this.additionalLinksNumber) * 38).toString();
        this.menuHeight = ((this.appsNumber + this.additionalLinksNumber) * 38).toString();
        break;

      case MenuMode.Grid:
        this.menuHeight = (Math.ceil(this.appsNumber / 2) * 51).toString();
        this.menuHeightMax = (Math.ceil(this.appsNumber / 2) * 51).toString();

      case MenuMode.Pop:
        this.menuHeight = (Math.ceil(this.appsNumber / 2) * 60).toString();
        this.menuHeightMax = (Math.ceil(this.appsNumber / 2) * 60).toString();
        break;

      default:
        break;
    }
  }

  private subscribeSidenavChange() {
    this.userService.preferenceChanged$
      .subscribe(preference => {
        if (preference.key === 'sideNavExpanded') {
          this.sidenavOpened = preference.value;
        }
      });
  }

  ngOnDestroy() {
    this.subsLangs.unsubscribe();
  }

  private subscribeAppChange() {
    this.globalService.obsApp()
      .subscribe(
        res => this.openApp(res.index, res.app),
        err => console.error(err));
  }

  private subscribeLangs() {
    this.subsLangs = this.globalService.obsLangs()
      .subscribe(langs => this.langs = langs, err => this._logger.error(err));
  }

  backToMain() {
    this.router.navigate(['main']);
  }

  toggleSideNav() {
    this.sidenavHandler.emit();
    this.sidenavOpened = !this.sidenavOpened;
  }

  toggleModuleSideNav() {
    this.moduleSidenavHandler.emit();
  }

  logout() {
    this.authenticateService.logout('/logout');
  }

  openPreferencesPage() {
    this.moduleSelected = false;
    this.router.navigate(['main/preferences']);
    this.clearSelected();
  }

  switchBusiness() {
    var clientName = this.utils.getAssociatedSsoClient();
    this.navigateToClient(clientName);
  }

  private navigateToClient(clientName) {
    var ssoClients = this.utils.getSsoClients();
    var ssoUrl = '?';
    var ssoClientConfig = this.utils.getSsoClientConfig(clientName);
    var currentClientUserPrefix = this.utils.getClientUserPrefix();
    for (var i = 0; i < ssoClients.length; i++) {
      if (i != 0) {
        ssoUrl = ssoUrl.concat('&');
      }
      if (currentClientUserPrefix.startsWith(ssoClients[i])) {
        ssoUrl = ssoUrl.concat('id_token_' + ssoClients[i] + '=' + localStorage.getItem('id_token'));
        ssoUrl = ssoUrl.concat('&');
        ssoUrl = ssoUrl.concat('access_token_' + ssoClients[i] + '=' + localStorage.getItem('access_token'));
        ssoUrl = ssoUrl.concat('&');
        ssoUrl = ssoUrl.concat('username_' + ssoClients[i] + '=' + localStorage.getItem('username'));
      } else if (clientName.startsWith(ssoClients[i])) {
        ssoUrl = ssoUrl.concat('id_token=' + localStorage.getItem('id_token_' + ssoClients[i]));
        ssoUrl = ssoUrl.concat('&');
        ssoUrl = ssoUrl.concat('access_token=' + localStorage.getItem('access_token_' + ssoClients[i]));
        ssoUrl = ssoUrl.concat('&');
        ssoUrl = ssoUrl.concat('username=' + localStorage.getItem('username_' + ssoClients[i]));
      } else {
        ssoUrl = ssoUrl.concat('id_token_' + ssoClients[i] + '=' + localStorage.getItem('id_token_' + ssoClients[i]));
        ssoUrl = ssoUrl.concat('&');
        ssoUrl = ssoUrl.concat('access_token_' + ssoClients[i] + '=' + localStorage.getItem('access_token_' + ssoClients[i]));
        ssoUrl = ssoUrl.concat('&');
        ssoUrl = ssoUrl.concat('username_' + ssoClients[i] + '=' + localStorage.getItem('username_' + ssoClients[i]));
      }
    }
    window.open(ssoClientConfig.navigationUrl + ssoUrl, '_blank').focus();
  }

  private getCurrentSelectedOption(name, appName) {
    const match = this.router.url.startsWith(name);
    if (match) {
      this.moduleSelected = APPS_WITH_SIDENAV_ARROW.indexOf(appName) >= 0;
    }

    return match;
  }



  private checkSupportMail = function () {
    if (this.appConfig.get().webConfig.supportEmailException) {
      this.showSupportEmailMessage(this.appConfig.get().webConfig.supportEmailException);
    } else {
      window.open("mailto:" + this.supportContact, '_self');
    }
  }

  private showSupportEmailMessage = function (message) {
    let dialogRef = this.dialog.open(DialogSupportContact, {
      data: { supportContactMessage: message }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private sendSupportMail = function () {
    window.open("mailto:" + this.supportContact, "_self");
  }

  private sendResetPasswordEmail() {
    var email = this.utils.getUserEmail();
    const data = {
      'client_id': this.appConfig.get().webConfig.auth0ClientId,
      'email': email,
      'password': '',
      'connection': this.appConfig.get().webConfig.auth0PasswordResetConnection
    };

    var auth0 = this.utils.getWebAuthInstance(null);
    auth0.changePassword(data, (err, resp) => {
      if (err) {
        this.openDialog(true);
        // this.errorMsg = 'Password reset error';
      } else {
        this.openDialog(false);
      }
    });
  }

  openDialog(isError) {
    let dialogRef = this.dialog.open(DialogResetPassword, {
      data: { isError: isError }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openTraining() {
    if (this.appConfig.get().webConfig.trainingOption == TRAINING_OPTIONS.PDF) {
      this.moduleSelected = false;
      this.router.navigate(['main/document']);
    } else {
      this.goToTraining();
    }
  }

  goToTraining() {
    if (this.environment.runLocal) return;
    const username = localStorage.getItem('username');
    this.documentService.getTrainingSSOToken().subscribe(res => {
      window.open(this.appConfig.get().webConfig.trainingUrl + '/SSO.aspx?ssouser=' + username + '&ssotoken=' + res.token, '_blank');
    }, err => {
      this._logger.error('error getting training sso token');
    });
  }

  openTrainingResources() {
    if (this.environment.runLocal) return;
    const username = localStorage.getItem('username');
    this.documentService.getTrainingSSOToken().subscribe(res => {
      window.open(this.appConfig.get().webConfig.trainingUrl + '/my-courses.aspx?ssouser=' + username + '&ssotoken=' + res.token, '_blank');
    }, err => {
      this._logger.error('error getting training sso token');
    });
  }

  closeMenu() {
    this.trigger.closeMenu();
  }

  openUserManagementPage() {
    this.router.navigate(['main/user-management']);
  }

  private openGroupChangeModal() {
    this.dialog.open(UserVendorOptionDialogComponent, { data: { userGroups: this.userGroups, userGroup: this.userGroup, password: this.password, showPasswordInput: true } });
  }

  private populateUserGroups() {
    const groups = this.utils.getVendorAccess();
    var array = groups.split(',');
    if (array && array.length) {
      this.userGroups = this.userGroups.concat(array);
    }
  }

  emitApps(): void {
    this.globalService.emitApps(this.apps);
  }

  clearSelected(): void {
    this.apps.forEach(app => { app.selected = false; });
  }

  private toggleSelected(app: AppOption, index: any) {
    const length = this.apps.length;
    for (let i = 0; i < length; i++) {
      this.apps[i].selected = (index === i);
    }
  }

  private onHubError() {
    this.globalService.emitHubCheck(true);
  }

  private openHub(hubUrl: string) {
    this.serverService.getCookieFromQlik(this.appConfig.get().webConfig.hubJwtUrl, {}).subscribe(
      res => {
        this.globalService.toggleModuleLoading(false);
        window.open(hubUrl);
      },
      err => {
        this._logger.error(err);
        this.globalService.toggleModuleLoading(false);
        this.onHubError();
      });

  }

  detectScreenSize(): void {
    this.menuMode = this.screen.getMenuMode();
  }

  onSelectApp(emission: any) {

    const index = emission.index;
    const appSelected = emission.app;

    if (index == undefined || appSelected == undefined) { return; }

    this.openApp(index, appSelected);
  }

  private openApp(index, app: AppOption) {
    event.preventDefault();
    switch (app.code) {
      case AppCode.AA:
      case AppCode.CI:
      case AppCode.CPL:
      case AppCode.Price: {
        window.open(app.url);
        break;
      }
      case AppCode.DP:
      case AppCode.Promo:
      case AppCode.Assort:
      case AppCode.PriceNg:
      case AppCode.Insights: {
        this.toggleSelected(app, index);
        this.moduleSelected = this.appConfig.get().webConfig.showSecondaryNav;
        this.globalService.toggleModuleLoading(true);
        this.collapseForNarrowScreen();


        this.router.navigateByUrl(app.url);

        break;
      }
      case AppCode.Reports: {
        this.toggleSelected(app, index);
        this.collapseForNarrowScreen();
        this.moduleSelected = this.appConfig.get().webConfig.showSecondaryNav;
        this.router.navigateByUrl(app.url);
        break;
      }
      case AppCode.Hub: {
        this.globalService.toggleModuleLoading(true);
        this.openHub(app.url);
        break;
      }
      case AppCode.Support: {
        if (app.title.toLowerCase() == 'training') {
          this.openTraining();
          break;
        }
        if (app.title.toLowerCase() == 'contact') {
          this.openTraining();
          break;
        }
      }

      case AppCode.Training: {
        this.openTraining();
        break;
      }
      case AppCode.Contact: {
        this.checkSupportMail();
        break;
      }
      default: {
        this.router.navigateByUrl(app.url);
      }
    }
  }

  private collapseForNarrowScreen() {
    if (!this.screen.sizes.isXLargeScreen) {
      this.toggleSideNav();
    }
  }

  toggleLanguage(lang: any) {
    this.updateLangPref(lang);
    this.langService.use(lang);
    this.languageChangeHandler.emit(lang);
  }

  private updateLangPref(lang: string) {
    if (this.langService.isValid(lang)) {
      this.userManager.getUserPreference('lang', lang,
        res => this.userManager.updateUserPreference('lang', lang),
        err => this._logger.error(err)
      );
    }
  }

  get menuData() {
    return {
      username: this.utils.getUserFullName(),
      allowSwitchBusiness: this.allowSwitchBusiness,
      hasLangs: true
    };
  }

  private subscribeWelcomeboard() {
    this.globalService.subscribeOpenTraining(
      res => {
        if (res == OnboardItemName.LearningCenter) {
          this.openTraining();
        } else {
          this.openTrainingResources();
        }
      },
      err => console.error(err)
    );
  }

  private getSupportItems() {
    if (this.showTraining) {
      this.supportItems.push({ title: 'CONTACT US' });
    }
    this.supportItems.push({ title: 'TRAINING' });
  }

  get hasLangs(): boolean { return this.langs.length > 1; }

  get langCurrent(): string { return this.langService.lang; }

  get hasModuleBtn(): boolean { return this.moduleSelected && !this.moduleSidenavOpened; }
}
