import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'categorySort'
})
export class CategorySort implements PipeTransform {
  transform(reportsList: Array<any>): Array<any> {
    return reportsList.sort((item1: any, item2: any) => {
      if (item1.category < item2.category) {
        return -1;
      } else if (item1.category > item2.category) {
        return 1;
      } else {
        if (item1.name < item2.name) {
          return -1;
        } else if (item1.name > item2.name) {
          return 1;
        } 
               
        return 0;
      }
    })
  }
}
