import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class BluegreenInterceptorService implements HttpInterceptor {

  constructor(private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bluegreen = this.cookieService.get('origin');
    const BLUEGREEN_VALUE = 'qa';
    const httpHeaders = bluegreen==BLUEGREEN_VALUE ? req.headers.append('bluegreen', BLUEGREEN_VALUE) : req.headers;
    return next.handle(req.clone({ headers: httpHeaders }));
  }
}
