import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(private translateService: TranslateService) { this.translateService.setDefaultLang('en'); }

  use(lang: string): void {
    this.translateService.use(lang);
  }

  get lang(): string {
    return this.translateService.currentLang;
  }

  get langCodes(): string[] {
    return ['en', 'fr', 'nl', 'en-US', 'en-CA', 'en-GB'];
  }

  isValid(lang: string): boolean {
    return this.langCodes.indexOf(lang) > -1;
  }

  instant(key: string): string {
    return this.translateService.instant(key);
  }

  onLangChange() {
    return this.translateService.onLangChange;
  }
}
