import { Injectable, Inject, EventEmitter } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UtilsService } from '../utilities/utils';
import { UserManager } from '../managers/user-manager';
import { ServerService } from './server.service';
import { AppConfigService } from './app-config.service';
import { InitConfigService } from './init-config.service';
import { getApiUrl } from '../utilities/env-utils';
import { CookieService } from 'ngx-cookie-service';

// Avoid name not found warnings
var auth0 = require('auth0-js');
var JSEncrypt = require('jsencrypt');
var jwtDecode = require('jwt-decode');
declare let heap: any;

@Injectable()
export class AuthenticateService {

  public applogout: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
    @Inject('Environment') private environment: any,
    private userManager: UserManager,
    private serverService: ServerService,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private utils: UtilsService,
    private cookieService: CookieService,) {
  }

  public login(username: string, password: string, group, successCallback, errorCallback): void {
    this.cookieService.deleteAll('/');
    if (!this.environment.runLocal) {
      this.utils.getWebAuthInstance(group).client.login({
        realm: this.initConfig.get().adConnection,
        username,
        password
      }, (err, authResult) => {
        if (err) {
          errorCallback(err);
          return;
        }
        if (authResult && authResult.idToken && authResult.accessToken) {
          this.saveUserParams(authResult, username, group);
          // ga('set', 'dimension1', new Date().toISOString());
          // ga('set', '&uid', username);
          heap['track']('Login Success', { username: localStorage.getItem('username'), timestamp: new Date().toTimeString() });
          heap.identify(username);
          this.appConfig.init((config) => {
            successCallback();
            heap.addUserProperties({
              'Name': this.utils.getUserFullName(),
              'Email': this.utils.getUserEmail(),
              'Data Access': this.utils.getUserDataAccess(),
              'User Role': this.utils.getUserRole(),
            });
          }, (err) => {
            errorCallback();
          });
        }
      });
    } else {
      this.serverService.rawGet(getApiUrl() + '/create-token', { username, password }).subscribe(authResult => {
        this.saveUserParams(authResult, username, group);
        this.appConfig.init((config) => {
          successCallback();
        }, (err) => {
          errorCallback();
        });
      }, err => {
        errorCallback(JSON.parse(err._body));
      });
    }
  }

  public loginForClient(ssoClients: string[], username: string, password: string, successCallback, errorCallback): void {
    ssoClients.forEach(ssoClient => {
      if (!this.appConfig.get().webConfig.clientUserPrefix.startsWith(ssoClient)) {
        if (this.environment.runLocal) {
          const authResult = { idToken: localStorage.getItem('id_token'), accessToken: localStorage.getItem('access_token') };
          this.saveUserParamsForClient(ssoClient, authResult, username);
          successCallback(authResult, username);
        } else {
          this.utils.getWebAuthInstanceForSsoClient(ssoClient).client.login({
            realm: this.appConfig.get().webConfig.adConnection,
            username,
            password
          }, (err, authResult) => {
            if (err) {
              errorCallback(err);
              return;
            }
            if (authResult && authResult.idToken && authResult.accessToken) {
              this.saveUserParamsForClient(ssoClient, authResult, username);
              successCallback(authResult, username);
            }
          });
        }
      }
    });
  }

  public validateCaptchaToken(token) {
    return this.serverService.rawGet(getApiUrl() + '/recaptcha-validation', { token: token });
  }

  public isAuthenticated(): boolean {
    // Check whether the id_token is expired or not
    const helper = new JwtHelperService();
    return !helper.isTokenExpired(localStorage.getItem('id_token'));
  }

  public logout(url): void {
    this.router.navigate([url]);
  }

  public emitLoggout() {
    this.applogout.emit();
  }

  private saveUserParams(authResult, username: string, group): void {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('username', username);

    const token = jwtDecode(authResult.idToken);
    const index = token['sub'].lastIndexOf('|');
    const userId = token['sub'].substring(index + 1);
    localStorage.setItem('userFullId', token['sub']);
    localStorage.setItem('userId', userId);
    if(group && !group.includes("null")) { 
      localStorage.setItem('userGroup', username + ',' + group);
    }
    // this.userManager.createUserPreference();
  }

  private saveUserParamsForClient(ssoClient, authResult, username: string): void {
    localStorage.setItem('access_token_' + ssoClient, authResult.accessToken);
    localStorage.setItem('id_token_' + ssoClient, authResult.idToken);
    localStorage.setItem('username_' + ssoClient, username);
  }

  public removeUserData() {
    // const userPreferences = localStorage.getItem('userPreferences')
    var userGroup = localStorage.getItem('userGroup');
    localStorage.clear();
    if(userGroup && !userGroup.includes("null")) {
      localStorage.setItem('userGroup', userGroup);
    } 
    else{
      localStorage.removeItem('userGroup');
    }
    // localStorage.setItem('userPreferences', userPreferences);
  }
}
