import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatButtonModule, MatCardModule, MatDialogModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { PrecimaBootstrapModule } from 'precima-bootstrap/src/components/precimaBootstrap.module';
import { environment } from '../environments/environment';
import { ApContainerComponent } from './ap-container/ap-container.component';
import { AppComponent } from './app.component';
import { HelpDialogComponent } from './common/dialogs/help-dialog/help-dialog.component';
import { DialogSupportContact } from './common/dialogs/support-contact-dialog/support-contact-dialog.component';
import { UserVendorOptionDialogComponent } from './common/dialogs/vendor-group-switching-dialog/vendor-group-switching-dialog.component';
import { FooterContainerComponent } from './common/footer-container/footer-container.component';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { UserMenuComponent } from './common/user-menu/user-menu.component';
import * as constants from './constants';
import { FocusDirective } from './directives/focus.directive';
import { DocumentContainerComponent } from './document-container/document-container.component';
import { HomeContainerComponent } from './home-container/home-container.component';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LogoutContainerComponent } from './logout-container/logout-container.component';
import { MainContainerComponent } from './main-container/main-container.component';
import { ReportManager } from './managers/report-manager';
import { UserManager } from './managers/user-manager';
import { BottomComponent } from './navigation/bottom/bottom.component';
import { DashboardComponent } from './navigation/dashboard/dashboard.component';
import { HeaderContainerComponent } from './navigation/header-container/header-container.component';
import { MenuWrapperComponent } from './navigation/menu-wrapper/menu-wrapper.component';
import { MenuComponent } from './navigation/menu/menu.component';
import { SupportComponent } from './navigation/support/support.component';
import { WelcomeBoardComponent } from './navigation/welcome-board/welcome-board.component';
import { CategorySort } from './pipes/category-sort-pipe';
import { DateSort } from './pipes/date-sort-pipe';
import { GroupBy } from './pipes/group-by-pipe';
import { NameSort } from './pipes/name-sort-pipe';
import { KeysPipe } from './pipes/object-properties-pipe';
import { QueryPipe } from './pipes/querypipe';
import { ReportNameFormatPipePipe } from './pipes/report-name-format-pipe.pipe';
import { SafePipe } from './pipes/safe-pipe';
import { PlanogramContainerComponent } from './planogram-container/planogram-container.component';
import { PreferencesContainerComponent } from './preferences-container/preferences-container.component';
import { ReportComponent } from './report/report.component';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { DialogResetPassword } from './reset-password-dialog/dialog-reset-password';
import { routes } from './routes';
import { AppConfigService } from "./services/app-config.service";
import { AppGuardService } from "./services/app-guard.service";
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticateService } from './services/authenticate.service';
import { AwsCredentialService } from './services/aws-credential.service';
import { DocumentService } from './services/document.service';
import { GlobalService } from "./services/global.service";
import { InitConfigService } from "./services/init-config.service";
import { ReportService } from './services/report.service';
import { ServerService } from './services/server.service';
import { UserService } from './services/user.service';
import { UserManagementContainerComponent } from './user-management-container/user-management-container.component';
import { UtilsService } from "./utilities/utils";
import { BluegreenInterceptorService } from './services/bluegreen-interceptor.service';
import { RibbonComponent } from './common/ribbon/ribbon.component';
import { VersionComponent } from './version/version/version.component';

const routing = RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' });

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function loadConfiguration(initConfig: InitConfigService): Function {
  return () => initConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeContainerComponent,
    ApContainerComponent,
    ReportsListComponent,
    ReportComponent,
    LoginContainerComponent,
    FocusDirective,
    SpinnerComponent,
    UserMenuComponent,
    HeaderContainerComponent,
    FooterContainerComponent,
    HelpDialogComponent,
    DocumentContainerComponent,
    PlanogramContainerComponent,
    KeysPipe,
    SafePipe,
    LogoutContainerComponent,
    MainContainerComponent,
    ReportNameFormatPipePipe,
    DialogResetPassword,
    DialogSupportContact,
    PreferencesContainerComponent,
    UserManagementContainerComponent,
    DashboardComponent,
    SupportComponent,
    MenuComponent,
    MenuWrapperComponent,
    BottomComponent,
    UserVendorOptionDialogComponent,
    WelcomeBoardComponent,
    RibbonComponent,
    VersionComponent
  ],
  entryComponents: [DialogResetPassword, UserVendorOptionDialogComponent, DialogSupportContact, HelpDialogComponent],
  imports: [
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    BrowserModule,
    FormsModule,
    HttpModule,
    routing,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatDialogModule,
    PrecimaBootstrapModule,
    BrowserAnimationsModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    ReportService,
    ServerService,
    AwsCredentialService,
    DocumentService,
    AuthenticateService,
    AuthGuardService,
    UserService,
    AppConfigService,
    AppGuardService,
    InitConfigService,
    GlobalService,
    QueryPipe,
    NameSort,
    CategorySort,
    DateSort,
    GroupBy,
    UtilsService,
    ReportManager,
    UserManager,
    { provide: "Environment", useValue: environment },
    { provide: "Constants", useValue: constants },
    { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [InitConfigService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BluegreenInterceptorService,
      multi: true
    },
    NGXLogger,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private logger: NGXLogger) {
    // this.logger.level = environment.logger.level;
    // this.logger.log(this.logger.level);
  }
}
