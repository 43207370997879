import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/take';
import { AppCode } from '../../models/appOption/AppCode';
import { AppOption, getPathColorful } from '../../models/appOption/AppOption';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  headerTitle = 'DASHBOARD_TITLE';
  subscription: Subscription;
  apps = [] as AppOption[];
  supportOptions = [] as AppOption[];
  hasTraining = false;

  constructor(private globalService: GlobalService,
    private _logger: NGXLogger
  ) { }

  ngOnInit() {
    this.fetchApps();
    this.fetchTrainingLink();
    this.addContact();
  }

  private fetchApps() {
    this.globalService.obsApps<Array<AppOption>>()
      .take(1)
      .subscribe(
        res => this.apps = res as AppOption[],
        err => console.error(err)
      );
  }

  private fetchTrainingLink() {
    this.globalService.obsTraningLink()
      .take(2)
      .subscribe(
        res => {
          this.hasTraining = res;
          if (this.hasTraining) {
            const training = {
              title: 'TRAINING',
              code: AppCode.Training,
              selected: false,
              iconPath: '../../assets/images/graph-thumbnails/svgs/training-thumbnail-white.svg',
              url: ''
            } as AppOption;
            this.supportOptions.push(training);
          }
        },
        err => console.error(err)
      );
  }

  emitApp(index: number, appOption: AppOption) {
    this.globalService.emitApp(index, appOption);
  }

  getPathColorful(app: AppOption): string {
    const iconPath = app.iconPath;
    return getPathColorful(iconPath);
  }

  private addContact() {

    const contact = {
      title: 'CONTACT_US',
      code: AppCode.Contact,
      selected: false,
      iconPath: '../../assets/images/graph-thumbnails/svgs/contact-thumbnail-white.svg',
      url: ''
    } as AppOption;
    this.supportOptions.push(contact);
  }
}
