import { Component, EventEmitter, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { HelpDialData, HelpDialogComponent } from '../common/dialogs/help-dialog/help-dialog.component';
import { UserManager } from '../managers/user-manager';
import { AppConfigService } from '../services/app-config.service';
import { AuthenticateService } from '../services/authenticate.service';
import { GlobalService } from '../services/global.service';
import { InitConfigService } from '../services/init-config.service';
import { LangService } from '../services/lang.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.less']
})

export class MainContainerComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  sidenavOpened = true;
  moduleSidenavOpened = false;
  moduleMenuCloseEvent: EventEmitter<any>;
  isModuleLoading: boolean = false;
  activeModule: any;
  spinnerMode = 'bothOpened';
  isDashboardPage = window.location.href.indexOf('main/dashboard') > -1;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.decrementSessionCounter();
    if (0 === parseInt(localStorage.getItem('sessionCounter'))) {
      localStorage.removeItem('reportingServers');
    }
  }

  constructor(private userManager: UserManager,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private userService: UserService,
    private authenticateService: AuthenticateService,
    public globalService: GlobalService,
    private langService: LangService,
    private _logger: NGXLogger,
    public dialog: MatDialog,
    private router: Router,
    @Inject('Environment') private environment: any) {
    this.globalService.moduleLoading$.subscribe(isModuleLoading => this.toggleLoading(isModuleLoading));
  }

  ngOnInit() {
    this.subscribeHubCheck();
    this.checkUserStatus();
    this.watchRouteChange();

    this.subscribeNavPreference();
    this.fetchNavPreference();
    this.fetchLangPreference();
  }

  private fetchLangPreference() {
    this.userManager.getUserPreference('lang', this.langService.lang, res => {
      const value = res['lang'];
      if (this.langService.isValid(value)) {
        this.langService.use(value);
        this.toggleLanguageChange(value);
      }
    }, err => this._logger.error(err));
  }

  private fetchNavPreference() {
    this.userManager.getUserPreference('sideNavExpanded', 'true',
      (userPreferences) => {
        this.sidenavOpened = userPreferences['sideNavExpanded'] === 'true';
        this.setPrecimaGoMenuOpened();
        this.incrementSessionCounter();
      },
      () => {
        this.setPrecimaGoMenuOpened();
        this.incrementSessionCounter();
      });
  }

  private subscribeNavPreference() {
    this.userService.preferenceChanged$
      .subscribe(
        preference => {
          if (preference.key === 'sideNavExpanded') {
            this.sidenavOpened = preference.value;
            this.setPrecimaGoMenuOpened();
          }
        });
  }

  private watchRouteChange() {
    this.router.events.subscribe(res => this.isDashboardPage = window.location.href.indexOf('main/dashboard') > -1);
  }

  toggleSideNav() {
    this.sidenavOpened = !this.sidenavOpened;
    this.setPrecimaGoMenuOpened();
  }

  toggleModuleSideNav() {
    this.moduleSidenavOpened = !this.moduleSidenavOpened;
    this.setModuleMenuOpened();
  }

  toggleLanguageChange(event: string) {
    if (this.activeModule && this.activeModule.hasOwnProperty('langSelected')) {
      this.activeModule.setLanguageChange(event);
    }
  }

  setModuleMenuOpened() {
    if (this.activeModule && this.activeModule.hasOwnProperty('moduleMenuOpened')) {
      this.activeModule.setModuleMenuOpened(this.moduleSidenavOpened);
    }
  }

  onActivate(componentRef) {
    this.activeModule = componentRef;
    this.activateModule();
  }

  activateModule() {
    this.userManager.getUserPreference('lang', '',
      res => {
        const value = res['lang'];
        if (this.langService.isValid(value)) {
          this.langService.use(value);
          this.toggleLanguageChange(value);
        }
      },
      err => this._logger.error(err));

    this.userManager.getUserPreference('secondarySideNavExpanded', 'true', (userPreferences) => {
      this.moduleSidenavOpened = this.initConfig.get().showSecondaryNav && userPreferences['secondarySideNavExpanded'] === 'true';
      this.setSpinnerMode();
      this.setModuleMenuOpened();
      this.setPrecimaGoMenuOpened();
    }, () => {
      this.setModuleMenuOpened();
      this.setPrecimaGoMenuOpened();
    });

    if (this.activeModule && this.activeModule.hasOwnProperty('moduleMenuCloseEvent')) {
      this.moduleMenuCloseEvent = new EventEmitter<any>();
      this.activeModule.setModuleMenuCloseEvent(this.moduleMenuCloseEvent);
      this.moduleMenuCloseEvent.subscribe(() => {
        this.moduleSidenavOpened = false;
        this.setSpinnerMode();
      });
      this.toggleLoading(false);
    }
  }

  setPrecimaGoMenuOpened() {
    if (this.activeModule && this.activeModule.hasOwnProperty('precimaGoMenuOpened')) {
      this.activeModule.setPrecimaGoMenuOpened(this.sidenavOpened);
    }
  }


  incrementSessionCounter() {
    var openSessionCounterStr = localStorage.getItem('sessionCounter');
    if (!openSessionCounterStr) {
      localStorage.setItem('sessionCounter', '1');
    } else {
      var openSessionCounter = parseInt(openSessionCounterStr);
      openSessionCounter++;
      localStorage.setItem('sessionCounter', openSessionCounter.toString());
    }
  }

  decrementSessionCounter() {
    var openSessionCounterStr = localStorage.getItem('sessionCounter');
    if (openSessionCounterStr) {
      var openSessionCounter = parseInt(openSessionCounterStr);
      openSessionCounter--;
      localStorage.setItem('sessionCounter', openSessionCounter.toString());
    }
  }

  checkUserStatus() {
    setInterval(() => {
      if (window.location.href.indexOf('login') < 0) {
        this.userService.checkUserStatus(localStorage.getItem('userFullId')).subscribe(res => {
          if (res.status)
            this.authenticateService.logout('/logout');
        }, err => {
          this._logger.error(err);
        });
      }
    }, 600000);
  }

  toggleLoading(isModuleLoading) {
    this.isModuleLoading = isModuleLoading;
    this.setSpinnerMode();
  }

  private openDialog(): void {
    this.dialog.open(HelpDialogComponent, {
      disableClose: true,
      minWidth: '600px', minHeight: '200px',
      data: this.dialData
    });
  }

  private subscribeHubCheck(): void {
    this.globalService.obsHubCheck().subscribe(
      res => { if (res) { this.openDialog() } },
      err => this._logger.error(err)
    );
  }

  get dialData(): HelpDialData {
    return {
      title: this.langService.instant('NETWORK_ERROR'),
      content: this.langService.instant('NETWORK_ERROR_CONTENT_HUB'),
      ok: 'OK'
    };
  }

  private setSpinnerMode() {
    if (this.hasModuleNav()) {
      if (this.sidenavOpened) {
        this.spinnerMode = this.moduleSidenavOpened ? 'bothOpened' : 'navOpened';
      } else {
        this.spinnerMode = this.moduleSidenavOpened ? 'moduleSidenavOpened' : 'neitherOpened';
      }
    } else {
      this.spinnerMode = this.sidenavOpened ? 'navOpened' : 'neitherOpened';
    }
  }

  private hasModuleNav() {
    const currentURL = window.location.href;
    if ((currentURL.indexOf('main/home') > -1) || (currentURL.indexOf('main/promo') > -1)) { return true; }
    return false;
  }

}
