import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {

  @Input() show = false;
  @Input() color: String = null;
  @Input() size: String = null;

  constructor() {
  }

  ngOnInit() {
  }

}
