export const token = 'precima_go_user_token'; // not used anymore
export const x_auth_token = 'X-Auth-Token'; // not used anymore

export const PRECIMA_GO_GROUP_CLAIM = 'http://precima-users-data.com/groups';
export const PRECIMA_GO_GROUP_AUTHORIZATION = 'https://auth.precima.io/user_authorization';
export const PRECIMA_GO_USER_NAME_CLAIM = 'name';
export const PRECIMA_GO_USER_EMAIL_CLAIM = 'email';
export const clientNameMap = {
    'shoppersdrugmart': 'Shoppers Drug Mart',
    'brandloyalty': 'Brandloyalty',
    'loblaws': 'Loblaws',
    'jumbo': 'Jumbo',
    'hbc': 'hbc',
    'cvs': 'cvs'
};

export const emailRegex = /[\w-]+@([\w-]+\.)+[\w-]+/;

export const loginStatus = {
    loggedOut: 0,
    loggedIn: 1,
    blocked: 2
};

export const TRAINING_OPTIONS = {
    ACCORD: 'accord',
    PDF: 'pdf'
};

export const CATEGORY_COLORS = {
    'assortment':'#D9B005',
    'data':'#5B367E',
    'insights':'#157CBF',
    'price':'#62A70F',
    'promotion':'#BE2621',
    'qvdgenerators':'#BE2621',
    'range':'#D9B005',
    'report':'#383A35' // default color
};

export const REPORT_CATEGORIES: string[] = ['assortment', 'data', 'insights', 'price', 'promotion', 'qvdgenerators', 'range'];
