import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'nameSort'
})
export class NameSort implements PipeTransform {
  transform(reportsList: Array<any>, style): Array<any> {
    if (style === 'ascending') {
      return reportsList.sort((item1: any, item2: any) => {
        if (item1.name < item2.name) {
          return -1;
        } else if (item1.name > item2.name) {
          return 1;
        } else {
          return 0;
        }
      })
    }

    if (style === 'descending') {
      return reportsList.sort((item1: any, item2: any) => {
        if (item1.name > item2.name) {
          return -1;
        } else if (item1.name < item2.name) {
          return 1;
        } else {
          return 0;
        }
      })
    }
  }
}
