import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportNameFormatPipe'
})
export class ReportNameFormatPipePipe implements PipeTransform {

  transform(value: any): string {
    const index = value.lastIndexOf('.');
    return value.substring(0, index);
  }

}
