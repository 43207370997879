import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from '../utilities/utils';
import { TranslateService } from '@ngx-translate/core';
/**
 * @title Injecting data when opening a dialog
 */
@Component({
  selector: 'dialog-reset-password',
  templateUrl: 'dialog-reset-password.html',
  styleUrls: ['dialog-reset-password.less']
})

export class DialogResetPassword {
  public userEmail: any;
  public translateParam: any;
  public showError: boolean;
  public showSuccess: boolean;
  constructor(
    private utils: UtilsService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: { isError: boolean }) {
    this.userEmail = this.utils.getUserEmail();
    this.translateParam = { 'userEmail': this.userEmail };
    this.showError = this.data.isError;
    this.showSuccess = !this.showError;
  }
}
