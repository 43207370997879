import { Component, OnInit } from '@angular/core';
import { UserManager } from '../managers/user-manager';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-preferences-container',
  templateUrl: './preferences-container.component.html',
  styleUrls: ['./preferences-container.component.less']
})
export class PreferencesContainerComponent implements OnInit {
  gridView: boolean = false;
  sideNavExpanded: boolean = false;
  secondarySideNavExpanded: boolean = false;
  showReports = false;

  constructor(
    private userManager: UserManager,
    private userService: UserService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.setPreference();
    this.subscribeReportsConfig();
  }

  private subscribeReportsConfig() {
    this.globalService.subscribeReportsConfig(res => this.showReports = res);
}

  private setPreference() {
    this.userManager.getUserPreferencesAll((userPreferencesFull) => {
      this.gridView = userPreferencesFull['gridView'] === 'true';
      this.sideNavExpanded = userPreferencesFull['sideNavExpanded'] === 'true';
      this.secondarySideNavExpanded = userPreferencesFull['secondarySideNavExpanded'] === 'true';
    });
  }

  toggleReportView(e: Event) {
    this.userManager.updateUserPreference('gridView', this.gridView, (userPreferences) => {
    }, (error) => {
      this.gridView = !this.gridView;
    });
  }

  toggleSideNavExpanded(e: Event) {
    this.userManager.updateUserPreference('sideNavExpanded', this.sideNavExpanded, (userPreferences) => {
      this.userService.onPreferenceChange('sideNavExpanded', this.sideNavExpanded);
    }, (error) => {
      this.sideNavExpanded = !this.sideNavExpanded;
    });
  }

  toggleSecondarySideNavExpanded(e: Event) {
    this.userManager.updateUserPreference('secondarySideNavExpanded', this.secondarySideNavExpanded, (userPreferences) => {
    }, (error) => {
      this.secondarySideNavExpanded = !this.secondarySideNavExpanded;
    });
  }
}
