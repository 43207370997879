import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UtilsService } from '../utilities/utils';
import { AppConfigService } from './app-config.service';
import {Observable} from 'rxjs';

@Injectable()
export class AppGuardService {

  constructor(private router: Router, private utils: UtilsService, private appConfig: AppConfigService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {
    const appPath = state.url.substr('/main/'.length);
    const index = appPath.indexOf('/');
    const path = index > -1 ? appPath.substr(0, index) : appPath;

    return new Observable<boolean>((observer) =>
    {
      this.appConfig.init((config) => {
        if (!this.utils.checkAppAccess(path)){
          this.router.navigate(['']);
          observer.next(false);
          observer.complete();
        }
        else{
          observer.next(true);
          observer.complete();
          return true;
        }
      }, () => {
        this.router.navigate(['']);
        observer.next(false);
        observer.complete();
      });
    });
  }

}
