export interface OnboardItem {
    name: OnboardItemName,
    description: string,
    icon: string,
    link: string
}

export enum OnboardItemName {
    LearningCenter = 'LEARNING_CENTER'
}

export const ONBOARD_ITEMS: OnboardItem[] = [
    {
        name: OnboardItemName.LearningCenter,
        description: 'LEARNING_CENTER_DESC',
        icon: '../../../assets/images/dashboard/learning.png',
        link: 'https://learn.precima.io'
    }
];
