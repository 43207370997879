import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class UserManager {
  constructor(
    private userService: UserService,
    private _logger: NGXLogger,
  ) {
  }

  public updateUserPreference(key, value, successCallback?, errorCallback?) {
    const userPreference = {
      userId: localStorage.getItem('userId'),
      preferenceKey: key.toString(),
      preferenceValue: value.toString()
    };

    this.userService.updateUserPreferences(userPreference).subscribe(userPreferences => {
      if (successCallback) successCallback();
    }, (error) => {
      this._logger.error(error);
      if (errorCallback) errorCallback();
    });
  }

  public getUserPreference(key, value?, successCallback?, errorCallback?) {
    this.userService.getUserPreferences
      (localStorage.getItem('userId'), { preferenceKey: key.toString(), preferenceValue: value ? value.toString() : '' })
      .subscribe(
        userPreferences => {
          let userPreferenceInLocalStorage = JSON.parse(localStorage.getItem('userPreferences'));
          if(userPreferenceInLocalStorage == null){
            userPreferenceInLocalStorage = userPreferences;
          }
          userPreferenceInLocalStorage[key]=userPreferences['preferenceValue'];
          localStorage.setItem('userPreferences', JSON.stringify(userPreferenceInLocalStorage));
          if (successCallback) successCallback(userPreferenceInLocalStorage);
        },
        (error) => {
          this._logger.error(error);
          if (errorCallback) errorCallback();
        });
  }

  public getUserPreferencesAll(successCallback?, errorCallback?) {
    this.userService.getUserPreferencesAll(localStorage.getItem('userId')).subscribe(userPreferences => {
      if (successCallback) successCallback(userPreferences);
    }, (error) => {
      this._logger.error(error);
      if (errorCallback) errorCallback();
    });
  }
}
