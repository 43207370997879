// keep for future use
import * as constants from '../constants';
import { environment } from '../../environments/environment';

let clientName = '';
export function getClientFromUrl() {
  return clientName ? clientName :
    window.location.href.substring(window.location.href.indexOf('//') + '//'.length, window.location.href.indexOf('.'));
}

export function getApiUrl() {
  let baseUrl = window.location.href.substring(window.location.href.indexOf('//') + '//'.length, window.location.href.length);
  let clientApi = baseUrl.substring(0, baseUrl.indexOf('/'));
  if (clientApi.startsWith('localhost')) {
    return environment.apiEndpoint;
  }

  if (clientApi.startsWith('bluegreen')) {
    // bluegreen-gateway.staples.precima.io for first bluegreen deployment.
    clientApi = clientApi.slice(10, clientApi.length);
    return `https://bluegreen-gateway.${clientApi}/go/api`;
  }

  let apiUrl = `https://gateway.${clientApi}/go/api`;
  return apiUrl;
}
