import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class GlobalService {
  public moduleLoading$: EventEmitter<boolean>;
  private subjApps = new BehaviorSubject<any>([]);
  private subjApp = new Subject<any>();
  private subjLangs = new BehaviorSubject<string[]>([] as string[]);
  private subjHubCheck = new BehaviorSubject<any>(null);
  private subjOpenTraining = new Subject<any>();
  private subjTrainingLink = new BehaviorSubject<any>(false);
  private onReceiveTraining = new BehaviorSubject<any>(false);
  onSidenavPreferenceChange = new BehaviorSubject<any>(null);
  private onReportsConfig = new BehaviorSubject<any>(false);

  constructor() {
    this.moduleLoading$ = new EventEmitter();
  }

  public toggleModuleLoading(toggle) {
    this.moduleLoading$.emit(toggle);
  }

  emitApps(value?: any) {
    this.subjApps.next(value);
  }

  emitApp(index: number, app: any) {
    this.subjApp.next({ index: index, app: app });
  }

  emitTrainingLink(res: any) {
    this.subjTrainingLink.next(res);
  }

  obsApps<T>(): Observable<T> {
    return this.subjApps.asObservable() as Observable<T>;
  }

  obsApp(): Observable<any> {
    return this.subjApp.asObservable();
  }

  emitLangs(value: any) {
    this.subjLangs.next(value);
  }

  obsLangs(): Observable<string[]> {
    return this.subjLangs.asObservable();
  }

  emitHubCheck(visible: boolean) {
    this.subjHubCheck.next(visible);
  }

  obsHubCheck(): Observable<any> {
    return this.subjHubCheck.asObservable();
  }

  emitSidenavOpened(sidenavOpened: boolean) {
    this.onSidenavPreferenceChange.next(sidenavOpened);
  }

  emitOpenTraining(trainingName: any) {
    this.subjOpenTraining.next(trainingName);
  }

  subscribeOpenTraining(success, fail?) {
    this.subjOpenTraining.subscribe(success, fail);
  }

  emitTraining(trainingName: any) {
    this.onReceiveTraining.next(trainingName);
  }

  subscribeTraining(success, fail?) {
    this.onReceiveTraining.subscribe(success, fail);
  }

  obsTraningLink(): Observable<any> {
    return this.subjTrainingLink.asObservable();
  }

  emitReportsConfig() {
    return this.onReportsConfig.next(true);
  }

  subscribeReportsConfig(success, fail?) {
    this.onReportsConfig.subscribe(success, fail);
  }
}
