import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ServerService } from './server.service';
import { getApiUrl } from '../utilities/env-utils';
import { IConfiguration } from '../models/iconfiguration';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable()
export class AppConfigService{
  private configuration: IConfiguration ;

  constructor(private router: Router,private serverService: ServerService, private _logger: NGXLogger,) {
  }

  public init(successCallback, errorCallback) {
    if(this.configuration){
      successCallback(this.configuration);
      return;
    }
    this.serverService.get(getApiUrl() + '/config', {}).subscribe(config => {
      this.configuration = config;
      successCallback(config);
    }, (err) => {
      this._logger.error(err);
      errorCallback(err);
    }, () => {

    });
  }

  public  get(): IConfiguration {
    
    if(!this.configuration){
      this.init((config) => {
        return this.configuration;
      }, (err) => {
        this._logger.error(err);
        return null;
      });
    }
    return this.configuration;
  }
}