import { Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { AppOption } from '../../models/appOption/AppOption';
import { MenuMode } from '../menu/menu.component';

@Component({
  selector: 'app-menu-wrapper',
  templateUrl: './menu-wrapper.component.html',
  styleUrls: ['./menu-wrapper.component.less']
})
export class MenuWrapperComponent implements OnInit {
  @Input() apps = [] as AppOption[];
  @Input() opened = true;
  @Input() mode = MenuMode.Grid;
  @Input() appsNumber = 0;
  @Input() additionalLinksNumber = 0;
  @Input() hasTraining = false;
  @Output() onSelect = new EventEmitter<any>();

  @Input() menuHeight = '';
  @Input() menuHeightMax = '';

  @ViewChild(MatMenuTrigger) appsMenuTrigger: MatMenuTrigger;

  constructor() { }

  ngOnInit() { }

  select($event: any) {
    this.onSelect.emit($event);
  }

  closeAppsMenu($event) {
    this.appsMenuTrigger.closeMenu();
  }

  get showTools() {
    return this.mode == MenuMode.Pop || (this.mode == MenuMode.Grid && !this.opened);
  }

  get showFullFeatures() {
    return this.mode == MenuMode.List || (this.mode == MenuMode.Grid && this.opened);
  }
}
