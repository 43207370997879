import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { LangService } from '../../services/lang.service';
import { UtilsService } from '../../utilities/utils';
import { AppConfigService } from '../../services/app-config.service';
import { UserVendorOptionDialogComponent } from '../../common/dialogs/vendor-group-switching-dialog/vendor-group-switching-dialog.component';
import { MatDialog, MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.less']
})
export class BottomComponent implements OnInit {
  @Input() environment: any = {};
  @Input() data = {};
  @Input() lang = '';
  @Input() opened = true;
  @Input() hasModuleBtn = false;
  @Input() supportItems = [];
  @Output() logout = new EventEmitter<any>();
  @Output() preferencePage = new EventEmitter<any>();
  @Output() resetPassword = new EventEmitter<any>();
  @Output() toggleNav = new EventEmitter<any>();
  @Output() toggleModuleNav = new EventEmitter<any>();
  @Output() langChange = new EventEmitter<any>();
  @Output() onSwitchBusiness = new EventEmitter<any>();

  langs = [] as string[];
  subsLangs: Subscription;
  chosenGroup: any;
  userGroups: String[] = [];
  userGroup: String;
  password: String;
  isLoading = false;
  isVendorSwitchingAllowed: Boolean;
  constructor(
    private langService: LangService,
    private globalService: GlobalService,
    private utils: UtilsService,
    private appConfig: AppConfigService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.subscribeLangs();

    this.appConfig.init(
      (config) => {
        this.isVendorSwitchingAllowed = this.utils.isVendorSwitchingAllowed();

        if (this.isVendorSwitchingAllowed) {
          this.chosenGroup = this.utils.getChosenGroup();
          this.populateUserGroups();
        }

      },

      (err) => {
        // this._logger.error('error initializing config');
      });
  }

  ngOnDestroy() {
    this.subsLangs.unsubscribe();
  }

  onLogout() {
    this.logout.emit();
  }

  onResetPassword() { this.resetPassword.emit(); }

  onPrefer() { this.preferencePage.emit(); }

  onSelectLang(emission: any) {
    this.langChange.emit(emission);
  }

  onToggleNav($event: any) {
    this.toggleNav.emit($event);
  }

  onToggleModuleNav($event: any) {
    this.toggleModuleNav.emit($event);
  }

  get hasLangs() {
    return this.langs.length > 0;
  }

  private subscribeLangs() {
    this.subsLangs = this.globalService.obsLangs()
      .subscribe(langs => this.langs = langs, err => console.error(err));
  }

  private openGroupChangeModal() {
    this.dialog.open(UserVendorOptionDialogComponent, { data: { userGroups: this.userGroups, userGroup: this.userGroup, password: this.password, showPasswordInput: true } });
  }

  private populateUserGroups() {
    const groups = this.utils.getVendorAccess();
    var array = groups.split(',');
    if (array && array.length) {
      this.userGroups = this.userGroups.concat(array);
    }
  }

  private switchBusiness() {
    this.onSwitchBusiness.emit();
  }
}
