import { Injectable, Inject, ReflectiveInjector } from '@angular/core';
import { HttpModule } from '@angular/http';
import { InitConfiguration } from '../models/init-configuration';
import { environment } from '../../environments/environment';
import { Http, Response, Headers, RequestOptions, ResponseOptions, BrowserXhr, BaseRequestOptions, BaseResponseOptions, ConnectionBackend, XHRBackend, XSRFStrategy, CookieXSRFStrategy } from '@angular/http';
import { groupAccessConfig } from '../../environments/configs/group-config';
import { getApiUrl } from '../utilities/env-utils';

const HTTP_PROVIDERS = [
  {provide: Http, useFactory: 
    (xhrBackend: XHRBackend, requestOptions: RequestOptions): Http =>
        new Http(xhrBackend, requestOptions), 
        deps: [XHRBackend, RequestOptions]},
  BrowserXhr,
  {provide: RequestOptions, useClass: BaseRequestOptions},
  {provide: ResponseOptions, useClass: BaseResponseOptions},
  XHRBackend,
  {provide: XSRFStrategy, useFactory: () => new CookieXSRFStrategy()},
];

var XRSF_MOCK = { provide: XSRFStrategy, useValue: {
  configureRequest: function() { /* */ }
} };

@Injectable()
export class InitConfigService {

  private configuration: InitConfiguration ;
  private http: Http;
  constructor(
    @Inject('Environment') private environment: any) {
      let injector = ReflectiveInjector.resolveAndCreate([HTTP_PROVIDERS, XRSF_MOCK]);
      this.http = injector.get(Http);
     }

  public load(): Promise<InitConfiguration> {
    if(!getApiUrl()){
      return;
    }
    
    if(this.environment.runLocal){
      
    }
    return new Promise((resolve, reject) => {
      this.http.get(`${getApiUrl()}/config/login`).subscribe(response => {
        this.configuration = JSON.parse(response.text());
        console.log('App configuration loaded', this.configuration);
        resolve(this.configuration);
      });
    });
  }

  public  get(): InitConfiguration {
    return this.configuration;
  }

}
