import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticateService } from './authenticate.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authenticateService: AuthenticateService) {
  }

  public canActivate() {
    const isLoggedIn = this.authenticateService.isAuthenticated();

    if (!isLoggedIn) {
      if (!localStorage.getItem('id_token')) {
        this.router.navigate(['/logout']);
      } else {
        this.authenticateService.logout('/logout/session-timeout');
      }
    }

    return isLoggedIn;
  }
}
