import { Inject, Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from '../../environments/environment';
import * as constants from '../constants';
import { AppConfigService } from '../services/app-config.service';
import { InitConfigService } from '../services/init-config.service';
import { AppCode } from '../models/appOption/AppCode';

@Injectable()
export class UtilsService {
  jwtDecode = require('jwt-decode');
  clientName: string = '';

  constructor(@Inject('Environment') private environment: any,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService) {
  }

  public getBrowserLang() {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }

    let browserLang = window.navigator.language;
    if (browserLang.indexOf('-') !== -1) {
      browserLang = browserLang.split('-')[0];
    }
    if (browserLang.indexOf('_') !== -1) {
      browserLang = browserLang.split('_')[0];
    }
    return browserLang === 'en' ? window.navigator.language : browserLang;
  }

  public getWebAuthInstance(group) {
    if (group) {
      return new auth0.WebAuth({
        domain: this.initConfig.get().auth0Domain,
        clientID: this.initConfig.get().auth0ClientId,
        responseType: 'token',
        scope: 'openid ' + encodeURIComponent(group)
      });
    } else {
      return new auth0.WebAuth({
        domain: this.initConfig.get().auth0Domain,
        clientID: this.initConfig.get().auth0ClientId,
        responseType: 'token',
        scope: 'openid'
      });
    }
  }

  public getWebAuthInstanceForClient(domain, clientId) {
    return new auth0.WebAuth({
      domain: domain,
      clientID: clientId,
      responseType: 'token',
      scope: 'openid'
    });
  }

  public getWebAuthInstanceForSsoClient(ssoClient) {
    var domain = this.appConfig.get().webConfig.auth0Domain;
    var ssoClientConfig = this.appConfig.get().webConfig.ssoClientConfigMap[ssoClient];
    return new auth0.WebAuth({
      domain: domain,
      clientID: ssoClientConfig.clientId,
      responseType: 'token',
      scope: 'openid'
    });
  }

  public getTokenClaimValue(claim) {
    const token = this.jwtDecode(localStorage.getItem('id_token'));
    return token[claim];
  }

  public checkHelpDocumentsAccess() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (groups[i].startsWith(this.appConfig.get().groupAccessConfig.groupAccessConst.PRECIMA_USERS_PREFIX) || groups[i].startsWith(this.appConfig.get().webConfig.clientUserPrefix)) {
        return true;
      }
    }

    return false;
  }

  public getSsoClients() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    var ssoClientsList: string[] = [];

    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < this.appConfig.get().groupAccessConfig.precimaSsoGroupPrefixes.length; j++)
        if (groups[i].startsWith(this.appConfig.get().groupAccessConfig.precimaSsoGroupPrefixes[j])) {
          ssoClientsList.push(this.appConfig.get().groupAccessConfig.precimaSsoGroupPrefixes[j].split('_')[0]);
        }
    }

    let unique_clients = Array.from(new Set(ssoClientsList))
    return unique_clients;
  }


  public checkPriceAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Price) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaPricingGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkPriceNgAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.PriceNg) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaPricingGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkAAAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.AA) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaAaGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
      if (this.appConfig.get().groupAccessConfig.precimaAaGroupPrefixes) {
        for (let j = 0; j < this.appConfig.get().groupAccessConfig.precimaAaGroupPrefixes.length; j++) {
          if (groups[i].startsWith(this.appConfig.get().groupAccessConfig.precimaAaGroupPrefixes[j])) {
            return true;
          }
        }
      }
    }

    return false;
  }

  public checkCustomProductListAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.CPL) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaCustomProductListsGroups.indexOf(groups[i]) >= 0) {
        return true;
      }

      for (let j = 0; j < this.appConfig.get().groupAccessConfig.precimaCplGroupPrefixes.length; j++) {
        if (groups[i].startsWith(this.appConfig.get().groupAccessConfig.precimaCplGroupPrefixes[j])) {
          return true;
        }
      }
    }

    return false;
  }

  public checkPromoAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Promo) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaPromoGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkInsightsPortalAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Insights) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaInsightsPortalGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkAdminAccess() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaAdminGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkAssortmentAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Assort) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaAssortmentGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkReportAccess() {
    return this.getPrecimaApps().indexOf(AppCode.Reports) >= 0;
  }

  public checkDataPortalAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.DP) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaDataPortalGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkSupportAccess() {
    return this.initConfig.get().supportEmail;
  }

  public checkCIAAccess() {
    return this.appConfig.get().webConfig.ciaUrl;
  }

  public checkHubAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Hub) < 0) return false;

    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaHubGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }
    return false;
  }

  public getUserFullName() {
    return this.getTokenClaimValue(constants.PRECIMA_GO_USER_NAME_CLAIM);
  }

  public getUserEmail() {
    return this.getTokenClaimValue(constants.PRECIMA_GO_USER_EMAIL_CLAIM);
  }

  public getClientFromUrl() {
    return this.clientName ? this.clientName :
      window.location.href.substring(window.location.href.indexOf('//') + '//'.length, window.location.href.indexOf('.'));
  }

  public getClientUserPrefix() {
    return this.appConfig.get().webConfig.clientUserPrefix;
  }

  public getClientVendorPrefix() {
    var vuserPrefix = (environment.client + ('_VUsers_Prefix')).toUpperCase();

    return this.appConfig.get().groupAccessConfig.groupAccessConst[vuserPrefix];
  }

  public getPrecimaUsersPrefix() {
    if(this.appConfig.get().groupAccessConfig.groupAccessConst.PRECIMA_USERS_PREFIX) {
      return this.appConfig.get().groupAccessConfig.groupAccessConst.PRECIMA_USERS_PREFIX;
    }
    return null;
  }

  public isVendorSwitchingAllowed() {
    if(this.appConfig.get().groupAccessConfig.groupAccessConst['VENDOR_SWITCHING']) {
      if(this.appConfig.get().groupAccessConfig.groupAccessConst['VENDOR_SWITCHING'] === "true") {
        return true;
      }
      return false;
    }
    return false;
  }

  public getAssociatedSsoClient() {
    if (this.appConfig.get().webConfig.clientUserPrefix === this.appConfig.get().groupAccessConfig.groupAccessConst.LCL_USERS_PREFIX) {
      return this.appConfig.get().groupAccessConfig.groupAccessConst.SDM_USERS_PREFIX.split('_')[0];
    } else if (this.appConfig.get().webConfig.clientUserPrefix === this.appConfig.get().groupAccessConfig.groupAccessConst.SDM_USERS_PREFIX) {
      return this.appConfig.get().groupAccessConfig.groupAccessConst.LCL_USERS_PREFIX.split('_')[0];
    }
  }

  public getSsoClientConfig(clientName) {
    return this.appConfig.get().webConfig.ssoClientConfigMap[clientName];
  }

  public isSsoAllowed() {
    var ssoClientsList = this.getSsoClients();
    return (ssoClientsList && ssoClientsList.length > 1);
  }

  public getBaseUrl() {
    return window.location.href.substring(0, window.location.href.indexOf('main'));
  }

  public getChosenGroup() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    let chosenGroup = [];
    const vusersPrefix = this.getClientVendorPrefix();
    const precimaUsersPrefix = this.getPrecimaUsersPrefix();
    
    for (var i = 0; i < groups.length; i++) {
      if (groups[i].includes(vusersPrefix) || groups[i].includes(precimaUsersPrefix)) {
        chosenGroup = groups[i].split(vusersPrefix).pop();
        break;
      }
    }

    return chosenGroup;

  }

  public getVendorAccess() {
    console.log(this.isVendorSwitchingAllowed());
    if (this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_AUTHORIZATION)) {
      var groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_AUTHORIZATION)['groups'];
      var client = this.getClientUserPrefix();
      var index = client.indexOf('_');
      client = client.substr(0,index);
      let accessInfo = [];
      const vusersPrefix = this.getClientVendorPrefix();
      const precimaUsersPrefix = this.getPrecimaUsersPrefix();
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].includes(vusersPrefix) || (groups[i].includes(precimaUsersPrefix) && groups[i].includes(client))) {
          const vendor = groups[i].split(vusersPrefix).pop();
          accessInfo.push(vendor)
        }
      }

      return accessInfo.toString();
    }

    return [].toString();
  }

  public getUserDataAccess() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    let accessInfo = [];
    const dataAccessPrefixes = this.appConfig.get().groupAccessConfig.precimaUserDataAccessPrefixes;
    for (let i = 0; i < dataAccessPrefixes.length; i++) {
      for (let j = 0; j < groups.length; j++) {
        if (groups[j].startsWith(dataAccessPrefixes[i])) {
          const index = dataAccessPrefixes[i].indexOf('_');
          accessInfo.push(dataAccessPrefixes[i].substring(0, index));
          break;
        }
      }
    }

    return accessInfo.toString();
  }

  public getUserRole() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    const userRole = this.appConfig.get().groupAccessConfig.groupUserRole;
    const roleInfo = [];

    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < userRole.length; j++) {
        if (groups[i].toUpperCase().startsWith(userRole[j].groupPrefix)) {
          const roleValue = userRole[j].userRole;
          if (roleInfo.indexOf(roleValue) === -1) {
            roleInfo.push(roleValue);
          }
          break;
        }
      }
    }

    return roleInfo.toString();
  }

  /**
   * Retrieves and returns an array of enabled apps for client.
   * Enabled apps are specified in config file for each client as an array of strings.
   */
  getPrecimaApps(): String [] {
    return this.appConfig.get().groupAccessConfig.precimaApps;
  }

  public checkAppAccess(path) {
    if (path == 'home')
      return this.checkReportAccess();
    else if (path == 'price')
      return this.checkPriceNgAccess();
    else if (path == 'promo')
      return this.checkPromoAccess();
    else if (path == 'assortment')
      return this.checkAssortmentAccess();
    else if (path == 'data-portal')
      return this.checkDataPortalAccess();
    else if (path == 'insights-portal')
      return this.checkInsightsPortalAccess();

    return false;
  }
}
