import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DocumentService } from '../services/document.service';
import { AppConfigService } from '../services/app-config.service';
import { UtilsService} from '../utilities/utils';
import { NGXLogger } from 'ngx-logger';

declare var AWS: any;

@Component({
  selector: 'app-document-container',
  templateUrl: './document-container.component.html',
  styleUrls: ['./document-container.component.less']
})
export class DocumentContainerComponent implements OnInit {

  files: Object [] = [];
  loadingFiles = false;
  trainingAccountExist = false;
  supportContact: Object = {};
  trainingOption: any;

  constructor(private router: Router,
              private documentService: DocumentService,
              private appConfigService: AppConfigService,
              public utils: UtilsService,
              private _logger: NGXLogger,
              @Inject('Environment') private environment: any,
              @Inject('Constants') private constants: any) {
  }

  ngOnInit() {
    // if (!checkHelpDocumentsAccess()) {
    //   this.router.navigate(['main']);
    // }
    this.supportContact = this.utils.checkSupportAccess();
    this.trainingOption = this.appConfigService.get().webConfig.trainingOption;
    if (this.trainingOption === this.constants.TRAINING_OPTIONS.ACCORD) {
      this.loadingFiles = true;
      this.documentService.getTrainingAccountExist(localStorage.getItem('username')).subscribe(res => {
        this.trainingAccountExist =  res;
        this.loadingFiles = false;
      }, err => {
        this._logger.error('error checking user');
        this.loadingFiles = false;
      });
    } else if (this.trainingOption === this.constants.TRAINING_OPTIONS.PDF) {
      this.loadingFiles = true;
      this.syncAWS();
    }
  }

  backToMain() {
    this.router.navigate(['main']);
  }

  private syncAWS() {
    this.loadingFiles = true;
    this.documentService.loadDocuments(this.appConfigService.get().webConfig.awsBucketConfig, (data) => {
      for (let i = 0; i < data.Contents.length; i++) {
        this.files.push({
          name: data.Contents[i].Key.replace(this.appConfigService.get().webConfig.awsBucketConfig.folder_prefix, ''),
          date: new Date(data.Contents[i].LastModified),
          key: data.Contents[i].Key
        });
      }
      this.loadingFiles = false;
    }, () => {
      this._logger.error('Error getting documents list');
      this.loadingFiles = false;
    });
  }

  openDocument(file) {
    this.documentService.openDocument(this.appConfigService.get().webConfig.awsBucketConfig, file, true);
  }

}
