import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from '../../../utilities/utils';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticateService } from '../../../services/authenticate.service';
@Component({
  selector: 'app-user-vendor-option-dialog',
  templateUrl: './vendor-group-switching-dialog.component.html',
  styleUrls: ['./vendor-group-switching-dialog.component.less']
})
export class UserVendorOptionDialogComponent implements OnInit {

  public userGroup: String;
  public password: String;
  public showPasswordInput: boolean;
  isLoading = false;
  loginErrorMsg: string = null;

  constructor(
    private utils: UtilsService,
    public dialogRef: MatDialogRef<any>,
    private authenticateService: AuthenticateService,
    @Inject(MAT_DIALOG_DATA) private data: { userGroups: String[], userGroup: String, password: String, showPasswordInput: boolean }) {
    this.userGroup = this.data.userGroup || this.data.userGroups[0];
    this.password = this.data.password;
    this.showPasswordInput = this.data.showPasswordInput;


  }

  ngOnInit() {
    this.password = '';
  }

  private selectGroup() {
    var vendorUserPrefix = this.utils.getClientVendorPrefix();
    var data = {userGroup: null, password: null, showPasswordInput: null};
    if(this.userGroup.includes(this.utils.getPrecimaUsersPrefix())){
      data = { userGroup: this.userGroup, password: this.password, showPasswordInput: this.showPasswordInput };
    }
    else {
      data = { userGroup: vendorUserPrefix + this.userGroup, password: this.password, showPasswordInput: this.showPasswordInput };
    }
    if (!this.password && this.showPasswordInput) {
      this.loginErrorMsg = 'Please enter a password';
    }
    else if (this.password && this.showPasswordInput) {
      this.isLoading = true;

      const username = localStorage.getItem('username');

      this.authenticateService.login(username, this.password.toString(), data.userGroup, () => {
        location.reload();
        this.isLoading = false;
      }, (error) => {
        this.loginErrorMsg = error.description;
        this.isLoading = false;
      });
    }
    else {
      this.dialogRef.close(data);
    }
  }

  private closeDialog() {
    this.dialogRef.close();
  }
}