import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { AppConfigService } from '../../services/app-config.service';
import { MatMenuTrigger } from '@angular/material';
import { UtilsService } from '../../utilities/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.less']
})
export class UserMenuComponent implements OnInit {

  @Input() params;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  showHelpDocs = false;
  clientName;

  closeMenu() {
    this.trigger.closeMenu();
  }

  constructor(private authenticateService: AuthenticateService,
              private router: Router,
              private appConfig: AppConfigService,
              private utils: UtilsService,
              @Inject('Environment') private environment: any,
              @Inject('Constants') private constants: any) {
  }

  ngOnInit() {
    this.clientName = this.constants.clientNameMap[this.environment.client];
    if (this.utils.checkHelpDocumentsAccess()) {
      this.showHelpDocs = true;
    }
  }

  logout() {
    this.authenticateService.logout('/logout');
  }

  openHelpPage() {
    this.router.navigate(['main/document']);
  }
}
