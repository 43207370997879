import { Injectable, Inject } from '@angular/core';
import { ServerService } from './server.service';
import { AppConfigService } from './app-config.service';
import { getApiUrl } from '../utilities/env-utils';
@Injectable()
export class ReportService {
  constructor(@Inject('Environment') private environment: any,
              private serverService: ServerService,
              private appConfig: AppConfigService) {
  }

  getReports(query?) {
    let queryString = '';

    if (query) {
      queryString = '?';
      Object.keys(query).forEach(key => {
        queryString = queryString + key + '=' + query[key] + '&';
      });

      queryString = queryString.substring(0, queryString.length - 1);
    }

    return this.serverService.get(getApiUrl() + '/docs' + queryString, {});
  }

  setReportFavorite(name, isFavorite) {
    return this.serverService.get(getApiUrl() + '/docs/favorite?name=' + encodeURIComponent(name)
      + '&isFavorite=' + isFavorite, {});
  }

  getReportCategories() {
    return this.serverService.get(getApiUrl() + '/docs/categories', {});
  }

  getWebTicket(serverName) {
    const user = {
      username: localStorage.getItem('username'),
    }
    
    return this.serverService.get(getApiUrl() + '/ticket?serverName=' + encodeURIComponent(serverName), {});      
  }
    
  

  showHelp() {
    return this.serverService.get(getApiUrl() + '/show-help', {});
  }
}
