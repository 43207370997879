import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { IReport } from '../models/report.type';
import { ReportService } from '../services/report.service';
import { UserService } from '../services/user.service';
import { GlobalService } from '../services/global.service';
import { AuthGuardService } from '../services/auth-guard.service';
import { NGXLogger } from 'ngx-logger';
import { AuthenticateService } from '../services/authenticate.service';
import { CATEGORY_COLORS, REPORT_CATEGORIES } from '../../app/constants';
import '../../../node_modules/precima-bootstrap/src/vendors/linearicons/styles/icons.css';

declare function escape(s: string): string;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less']
})
export class ReportComponent implements OnInit {

  @Input() report: IReport;
  @Input() isGrids: boolean;
  @Input() gridViewEnabled: boolean;
  @Input() thumbnailsEnabled: boolean;
  @Output() favorite: EventEmitter<IReport> = new EventEmitter<IReport>();

  isFavorite: boolean;
  isDeciding: boolean;
  chartType: String;
  imagePath: String;
  btmBorderColor: String;

  constructor(private reportService: ReportService,
    private authGuardService: AuthGuardService,
    private _logger: NGXLogger,
    private userService: UserService,
    public globalService: GlobalService,    
    private authenticateService: AuthenticateService) {
  }

  ngOnInit() {
    this.chartType = this.report.thumbnailStyle;
    this.isFavorite = this.report.favorite;
    this.isDeciding = false;
    this.setThumbnails();
  }

  clickReport() {
    this.userService.checkUserStatus(localStorage.getItem('userFullId')).subscribe(res => {
      if (res.status) {
        this.authenticateService.logout('/logout');
      }
      else {
        this.openReport();
      }
    }, err => {
      console.log(err);
    });
  }

  openReport() {
    if (this.authGuardService.canActivate()) {
      this.globalService.toggleModuleLoading(true);
      if (this.checkWebticket(this.report.serverName)) {
        const url = this.report.docUrl + (this.report.folderName ? escape(this.report.folderName) + '/' : '') + escape(this.report.name);
        window.open(url, '_blank');
        this.globalService.toggleModuleLoading(false);
      } else {
        this.reportService.getWebTicket(this.report.serverName).subscribe(data => {
          const url = this.report.url.toString() + (this.report.folderName ? escape(this.report.folderName) + '/' : '')
            + escape(this.report.name) + '&select&webticket=';
          const win = window.open(url + data.ticket, '_blank');
          if (win) {
            this.addReportingServer(this.report.serverName);
          }
          this.globalService.toggleModuleLoading(false);
        }, (err) => {
          this._logger.error('error happened');
          this.globalService.toggleModuleLoading(false);
        }
        );
      }
    }
  }

  onMouseOver() {
    this.isDeciding = true;
  }

  onMouseLeave() {
    this.isDeciding = false;
  }

  optionFavorited(event) {
    event.stopPropagation();
    this.isFavorite = !this.report.favorite;
    this.favorite.emit(this.report);
  }

  private setThumbnails() {
    const categoryStr = this.report.category.toLowerCase().replace(/\s+/g, '');
    const categoryName = this.checkArray(categoryStr);
    this.btmBorderColor = CATEGORY_COLORS[categoryName]?CATEGORY_COLORS[categoryName]:CATEGORY_COLORS['report'];

    if (categoryName) {
      this.imagePath = '../../assets/images/graph-thumbnails/svgs/' + categoryName + '-thumbnail.svg';
    } else {
      this.imagePath = '../../assets/images/graph-thumbnails/svgs/report-default-thumbnail.svg';
    }
  }

  private checkArray(reportCategory) {
    for (let category of REPORT_CATEGORIES) {
      if (reportCategory.indexOf(category) >= 0) {
        return category;
      }
    }
    return null;
  }

  private checkWebticket(reportingServer) {
    const reportingServersStr = localStorage.getItem('reportingServers');
    if (!reportingServersStr || reportingServer == null) return false;

    const reportingServers = reportingServersStr.split(",");
    if (reportingServers.indexOf(reportingServer) < 0)
      return false;

    return true;
  }

  private addReportingServer(reportingServer) {
    if (!reportingServer) return;

    const reportingServersStr = localStorage.getItem('reportingServers');
    if (reportingServersStr) reportingServer = reportingServer + ',' + reportingServersStr;

    localStorage.setItem('reportingServers', reportingServer);
  }
}
