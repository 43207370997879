import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'queryFilter'
})
export class QueryPipe implements PipeTransform {
  transform(query, modifier) {
    return query.filter((data) => {
      return data['name'].toLowerCase().indexOf(modifier.toLowerCase()) > -1 ||
        data['category'].toLowerCase().indexOf(modifier.toLowerCase()) > -1
    })
  }
}
