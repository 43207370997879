import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'dateSort'
})
export class DateSort implements PipeTransform {
  transform(reportsList: Array<any>): Array<any> {
    return reportsList.sort((item1: any, item2: any) => {
      if (item1.date < item2.date) {
        return -1;
      } else if (item1.date > item2.date) {
        return 1;
      } else {
        return 0;
      }
    })
  }
}
