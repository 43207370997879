import { Injectable, EventEmitter } from '@angular/core';
import { ServerService } from './server.service';
import { getApiUrl } from '../utilities/env-utils';
@Injectable()
export class UserService {
  public preferenceChanged$: EventEmitter<any>;
  public displayGroupChanged$: EventEmitter<any>;

  constructor(private serverService: ServerService) {
    this.preferenceChanged$ = new EventEmitter<any>();
    this.displayGroupChanged$ = new EventEmitter<any>();
  }

  public validateUser(attribute, value) {
    return this.serverService.rawGet(getApiUrl() + `/validate-user?attribute=${attribute}&value=${value}`, {});
  }

  public login(username) {
    return this.serverService.post(getApiUrl() + '/login?username=' + username, {}, {});
  }

  public logout(username) {
    return this.serverService.rawPost(getApiUrl() + '/logout?username=' + username, {}, {});
  }  

  public getUserPreferences(userId, header) {
    return this.serverService.get(getApiUrl() + `/get-user-preferences?userId=${userId}`, header);
  }

  public updateUserPreferences(userPreferences) {
    return this.serverService.post(getApiUrl() + `/update-user-preferences`, userPreferences, {});
  } 

  public getUserPreferencesAll(userId) {
    return this.serverService.get(getApiUrl() + `/get-user-preferences-all?userId=${userId}`, {});
  }

  public onPreferenceChange(key, value) {
    this.preferenceChanged$.emit({ key: key, value: value });
  }    

  public updateUserBlock(username, block) {
    return this.serverService.post(getApiUrl() + `/update-user-block?username=${username}&block=${block}`, {}, {});
  }    

  public checkUserStatus(userId) {
    const encodedUserId = encodeURIComponent(userId);
    return this.serverService.get(getApiUrl() + `/check-user-status?userId=${encodedUserId}`, {});
  }      

  public changeDisplayGroup(groups) {
    this.displayGroupChanged$.emit(groups);
  }
  
}
