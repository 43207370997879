import { Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { ServerService } from './server.service';
import { AppConfigService } from './app-config.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AwsCredentialService {

  constructor(@Inject('Environment') private environment: any,
              private serverService: ServerService,
              private appConfig: AppConfigService,
              private _logger: NGXLogger) {
  }

  getAwsCredentials(bucketConfig, successCallback, errorCallback) {
    const awsCredsString = localStorage.getItem('aws_creds');
    if (!awsCredsString || new Date(JSON.parse(awsCredsString).Expiration) <= new Date()) {
      this.getDelegationToken(bucketConfig, (res) => {
        localStorage.setItem('aws_creds', JSON.stringify(res.Credentials));
        successCallback(res.Credentials);
      }, () => {
        this._logger.error('error getting delegation token');
        errorCallback();
      });
    } else {
      const awsCreds = JSON.parse(awsCredsString);
      successCallback(awsCreds);
    }
  }

  private getDelegationToken(bucketconfig, successCallback, errCallback) {
    const options = {
      id_token: localStorage.getItem('id_token'),
      client_id: this.appConfig.get().webConfig.auth0ClientId,
      target: this.appConfig.get().webConfig.auth0ClientId,
      grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
      principal: bucketconfig.principal,
      role: bucketconfig.role,
      api_type: 'aws'
    };

    this.serverService.rawPost(bucketconfig.domain + '/delegation', options, {}).subscribe(res => {
      successCallback(res);
    }, err => {
      errCallback();
    });
  }
}
