export enum AppCode {

    /** Normal Modules */
    Reports = 'reports',

    /** Lazy Modules */
    DP = 'dp',
    Promo = 'promo',
    Assort = 'assortment',
    Insights = 'insights',
    PriceNg = 'pricing',

    /** External Apps */
    AA = 'aa',
    CI = 'cia',
    CPL = 'cpl',
    Hub = 'hub',
    Price = 'price',

    /** Supports */
    Support = 'support',
    Training = 'training',
    Contact = 'contact'
}

export const EXTERNAL_APPS = [AppCode.AA, AppCode.CI, AppCode.CPL, AppCode.Price, AppCode.Hub];
export const INTEGRATED_APPS = [AppCode.Reports, AppCode.Promo, AppCode.Assort, AppCode.Insights, AppCode.DP, AppCode.PriceNg];
export const APPS_WITH_SIDENAV_ARROW = [AppCode.Reports, AppCode.Promo, AppCode.Assort, AppCode.Insights, AppCode.PriceNg, AppCode.DP];
