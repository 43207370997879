import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges, Inject } from '@angular/core';
import { AppOption } from '../../models/appOption/AppOption';
import { GlobalService } from '../../services/global.service';

export enum MenuMode {
  List = 'list',
  Grid = 'grid',
  Pop = 'pop'
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  @Input() apps = [] as AppOption[];
  @Input() opened = true;
  @Input() mode = MenuMode.Grid;
  @Input() appsNumber = 0;
  @Input() additionalLinksNumber = 0;
  @Input() hasTraining = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onMouseLeavePopMenu = new EventEmitter<any>();
  @Output() onSupportMenuClose = new EventEmitter<any>();

  @Input() menuHeight = '';
  @Input() menuHeightMax = '';
  supportIndex = 0;

  constructor(private globalService: GlobalService, @Inject('Environment') private environment: any) { }

  ngOnInit() {
    this.setMenuMaxHeight();
    this.supportIndex = this.apps.length;

  }

  ngOnChanges(changes: SimpleChanges) {
    this.setMenuMaxHeight();
    this.supportIndex = this.apps.length;
    console.log(this.apps);
    console.log(changes);
  }

  select($event: any) {
    this.onSelect.emit($event);
  }

  mouseLeave($event) {
    if ($event['movementX'] == 0 && $event['movementY'] == 0) { return; }
    this.onMouseLeavePopMenu.emit($event);
  }

  setMenuMaxHeight() {
    switch (this.mode) {
      case MenuMode.List:
        this.menuHeightMax = ((this.appsNumber + this.additionalLinksNumber) * 50).toString();
        this.menuHeight = ((this.appsNumber + this.additionalLinksNumber) * 50).toString();
        break;

      case MenuMode.Grid:
        this.menuHeight = (Math.ceil((this.appsNumber + this.additionalLinksNumber) / 2) * 55).toString();
        this.menuHeightMax = (Math.ceil((this.appsNumber + this.additionalLinksNumber) / 2) * 55).toString();

      case MenuMode.Pop:
        break;

      default:
        break;
    }
  }

  get showLabel() {
    return this.opened || (!this.opened && this.mode != MenuMode.List);
  }
}
