import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'groupBy'
})

export class GroupBy implements PipeTransform {
  transform(reportsList: Array<any>, field: string): any {
    const groupedObj = reportsList.reduce((prev, cur)=> {
      if(!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    return groupedObj;
  }
}