import { Component, OnInit, OnDestroy, AfterViewInit, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { UserService } from '../services/user.service';
import { AppConfigService } from '../services/app-config.service';
import { InitConfigService } from '../services/init-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { UserManager } from '../managers/user-manager';
import { UtilsService } from '../utilities/utils';
import { NGXLogger } from 'ngx-logger';
import { CookieService } from 'ngx-cookie-service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { UserVendorOptionDialogComponent } from '../common/dialogs/vendor-group-switching-dialog/vendor-group-switching-dialog.component';
import { MatDialog, MatMenuTrigger } from '@angular/material';
declare let reCaptcha: Object;

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.less']
})

export class LoginContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  loginForm: any;
  email: string;
  errorMsg: string;
  resetDisabled = true;
  emailSent = false;
  showForgetPwd = false;
  showLogin = false;
  option: string;
  username: string;
  password: string;
  loginErrorMsg: string = null;
  duplicateLogin = false;
  loginConnectionError = false;
  loginDisabled = false;
  sessionTimeout = false;
  redirectUrl: string;
  private paramSub: any;
  private sub: any;
  private ssoClients: string[];
  supportContact: Object = {};
  reCaptcha;
  captchaValidating = false;
  captchaValidationErrorMsg: string;
  recaptchaSiteKey: string;
  isSSOLogin: boolean = false;
  userGroups: String[] = [];
  userGroup: String;
  validUsername: boolean = true;
  isVendorSwitchingAllowed: boolean;
  reCaptchaValidationErr = false;

  public focusSettingEventEmitter = new EventEmitter<boolean>();
  @ViewChild('logoutFrame') logoutFrame: ElementRef;
  @ViewChild('usernameInput') usernameInput: ElementRef;
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('captchaRef') captchaRef;

  constructor(private authenticationService: AuthenticateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private userManager: UserManager,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private utils: UtilsService,
    private _logger: NGXLogger,
    private cookieService: CookieService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject('Environment') private environment: any,
    @Inject('Constants') private constants: any) {
  }

  ngOnInit() {
    this.recaptchaSiteKey = this.initConfig.get().recaptchaSiteKey;

    this.sub = this.route.params.subscribe(params => {
      this.option = params['option'];

      // In a real app: dispatch action to load the details here.
      this.errorMsg = '';
      this.showLogin = false;
      this.sessionTimeout = false;
      this.showForgetPwd = false;
      this.email = '';
      this.username = '';
      this.password = '';
      this.captchaValidationErrorMsg = '';
      this.resetDisabled = true;
      this.loginDisabled = false;
      this.emailSent = false;
      this.redirectUrl = '';
      this.ssoClients = [];
      this.captchaValidating = false;

      if (!this.option) {
        this.showLogin = true;
      } else if (this.option.toString() === 'password-reset') {
        this.showForgetPwd = true;
      } else if (this.option.toString() === 'session-timeout') {
        this.sessionTimeout = true;
        this.showLogin = true;
      }

      this.supportContact = this.utils.checkSupportAccess();
      this.checkRedirect();
      this.checkSso();

      if (!this.isSSOLogin) {
        if (this.authenticationService.isAuthenticated()) {
          this.navigateToApp();
        } else {
          this.authenticationService.removeUserData();
        }
      }

      //this.checkSso();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.focusSettingEventEmitter.emit(true);
    });

    // if (this.usernameInput)
    //   this.usernameInput.nativeElement.focus();

    // if(this.emailInput)
    //   this.emailInput.nativeElement.focus();
    this.checkWhitespace();
  }

  onChange(newValue) {
    if (newValue.includes('@')) {
      this.validUsername = false;
    } else {
      this.validUsername = true;
    }
  }

  hideReCaptchaValidErr() {
    this.reCaptchaValidationErr = false;
  }

  private checkSso() {
    this.paramSub = this.route.queryParams.subscribe(params => {
      if (params && params['access_token'] && params['id_token'] && params['username']) {
        this.isSSOLogin = true;
        localStorage.setItem('access_token', params['access_token']);
        localStorage.setItem('id_token', params['id_token']);
        localStorage.setItem('username', params['username']);
        this.appConfig.init((config) => {
          this.ssoClients = this.utils.getSsoClients();
          var currentClientUserPrefix = this.utils.getClientUserPrefix();
          for (var i = 0; i < this.ssoClients.length; i++) {
            var client = this.ssoClients[i];
            if (!currentClientUserPrefix.startsWith(client)) {
              localStorage.setItem('access_token_' + client, params['access_token_' + client]);
              localStorage.setItem('id_token_' + client, params['id_token_' + client]);
              localStorage.setItem('username_' + client, params['username_' + client]);
            }

            this.navigateToApp();
          }
        }, (err) => {
          this._logger.error('error initializing config');
          this.isSSOLogin = false;
          this.authenticationService.removeUserData();
        });
      }
    });
  }

  /**
   *  Execute reCaptcha v3 service to obtain a response token
   */
  executeRecaptcha() {
    this.reCaptchaV3Service.execute(this.recaptchaSiteKey, 'pwdreset', (token) => {
      this.captchaResolved(token); // send token for validation
    });
  }

  /**
   * Resolve response obtained from reCpatcha v3 service
   * First, the response is validated on the backend. if backend successfully validates recaptcha, a reset email will be sent to the user.
   */
  captchaResolved(captchaResponse) {
    if (!captchaResponse) return;

    this.captchaValidating = true;
    this.errorMsg = '';
    this.authenticationService.validateCaptchaToken(captchaResponse).subscribe(res => {
      this.resetDisabled = false;
      this.captchaValidating = false;
      this.sendResetEmail();
    }, err => {
      this.reCaptcha = "";
      this.captchaValidating = false;
      this.reCaptchaValidationErr = true;
      this._logger.log('err happened' + err);
    });
  }

  private checkRedirect() {
    this.paramSub = this.route.params.subscribe(params => {
      if (params && params['originUrl']) {
        this.redirectUrl = params['originUrl'];
      }
    });
  }

  private loginLink() {
    this.router.navigate(['/login', 'user-login']);
  }



  login() {
    this.loginDisabled = true;
    this.loginErrorMsg = null;
    this.duplicateLogin = false;
    this.loginConnectionError = false;

    this.authenticationService.login(this.username.toLowerCase(), this.password, null, () => {
      //this.loginUser();
      this.ssoClients = this.utils.getSsoClients();
      if (this.utils.isSsoAllowed()) {
        this.authenticationService.loginForClient(this.ssoClients, this.username.toLowerCase(), this.password, (authResult, username) => {
          this._logger.log("Auth tokens saved for sso client");
        }, (error) => {
          this._logger.error("Auth tokens  not saved for sso client");
        });
      }

      // this.userService.getUserPreferences(localStorage.getItem('userId')).subscribe(userPreferences => {
      //   localStorage.setItem('userPreferences', JSON.stringify(userPreferences));
      //   this.navigateToApp();
      // }, err => {
      //   console.log(err);
      //   this.navigateToApp();
      // });

      this.appConfig.init((config) => {
        this.isVendorSwitchingAllowed = this.utils.isVendorSwitchingAllowed();

      }, (err) => {
        this._logger.error('error initializing config');
      });

      if (this.isVendorSwitchingAllowed) {
        var group = localStorage.getItem('userGroup');
        if (group && !group.includes("null")) {
          var usernameAndGroup = group.split(",");
          if (usernameAndGroup[0] == this.username.toLowerCase()) {
            group = usernameAndGroup[1];
          } else {
            group = null;
          }
        }
        console.log(group);
        if (group === "null" || !group) {
          this.populateUserGroups();
          console.log(this.userGroups);
          if (this.userGroups[0] != "" && this.userGroups.length > 1) {
            let dialogRef = this.dialog.open(UserVendorOptionDialogComponent, { data: { userGroups: this.userGroups, userGroup: this.userGroup, password: this.password, showPasswordInput: false }, disableClose: true });
            dialogRef.afterClosed().subscribe(data => {
              if (data) {
                console.log("Group:", data.userGroup);
                const username = localStorage.getItem('username');
                this.authenticationService.login(username, this.password, data.userGroup, () => {
                  this.navigateToApp();
                }, (error) => {
                  console.log("Error:", error);
                });
                if (!data) return;
                this.userGroup = data;
                this.userService.changeDisplayGroup(data);
              }
            });
          }
          else {
            this.navigateToApp();
          }
        }
        else if (group) {
          const username = localStorage.getItem('username');
          this.authenticationService.login(username, this.password, group, () => {
            this.navigateToApp();
          }, (error) => {
            console.log("Error:", error);
          });
        }
        else {
          this.navigateToApp();
        }
      }
      else {
        this.navigateToApp();
      }



    }, (error) => {
      if (error.description === 'duplicate login') {
        this.duplicateLogin = true;
      } else if (error.description === 'user is blocked') {
        this.loginErrorMsg = 'USER_BLOCKED_SUPPORT';
      } else if (error.statusCode === 401 || error.description === 'Wrong email or password.' || error.code === 'invalid_grant') {
        this.loginErrorMsg = 'INCORRECT_USERNAME_PASSWORD';
      } else {
        this.loginConnectionError = true;
      }

      this.loginDisabled = false;
    });
  }

  private navigateToApp() {
    this.loginDisabled = false;
    if (this.redirectUrl) {
      window.location.href = this.redirectUrl + '?id_token=' + localStorage.getItem('id_token');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  private sendResetEmail() {
    this.errorMsg = '';

    if (!this.constants.emailRegex.test(this.email)) {
      this.errorMsg = 'PASSWORD_RESET_WRONG_EMAIL_FORMAT';
      return;
    }

    if (this.resetDisabled) {
      this.errorMsg = 'PASSWORD_RESET_DISABLED';
      return;
    }

    this.emailSent = true;
    this.resetDisabled = true;
    this.showForgetPwd = false;

    if (this.environment.runLocal)
      return;

    this.userService.validateUser('mail', this.email).subscribe(res => {
      if (res) {
        this.sendEmail();
      }
    }, (err) => {
      this._logger.error(err);
    });
  }

  private sendEmail() {

    const data = {
      'client_id': this.initConfig.get().auth0ClientId,
      'email': this.email,
      'password': '',
      'connection': this.initConfig.get().auth0PasswordResetConnection
    };

    var auth0 = this.utils.getWebAuthInstance(null);
    auth0.changePassword(data, (err, resp) => {
      if (err) {
        this._logger.error(err);
        this.errorMsg = 'PASSWORD_RESET_ERROR';
      }
    });
  }

  private resetPassword() {
    this.router.navigate(['/login', 'password-reset'], { queryParams: this.getRedirectQueryString() });
  }

  private gotoLogin() {
    this.router.navigate(['/login'], { queryParams: this.getRedirectQueryString() });
  }

  private populateUserGroups() {
    const groups = this.utils.getVendorAccess();
    var array = groups.split(',');
    if (array && array.length) {
      this.userGroups = this.userGroups.concat(array);
    }
  }

  private getRedirectQueryString() {
    let queryObj = {};
    if (this.redirectUrl) {
      queryObj['originUrl'] = this.redirectUrl;
    }

    return queryObj;
  }

  private checkWhitespace() {
    if (document.getElementById('passwordInput')) {
      document.getElementById('passwordInput').addEventListener('keydown', function (e) {
        var length = (<HTMLInputElement>document.getElementById('passwordInput')).value.length;
        if (length === 0 && e.which === 32) {
          e.preventDefault();
        }
      });
    }
  }

  private onIframeLoad() {
    const iframe = window.frames[0];
    const msg = JSON.stringify({ key: 'logout', data: '' });
    iframe.postMessage(msg, 'http://app1.com:4200');
  }

  loginUser() {
    this.userService.login(this.username).subscribe(user => {
      this.ssoClients = this.utils.getSsoClients();
      if (this.utils.isSsoAllowed()) {
        this.authenticationService.loginForClient(this.ssoClients, this.username, this.password, (authResult, username) => {
          this._logger.log("Auth tokens saved for sso client");
        }, (error) => {
          this._logger.error("Auth tokens not saved for sso client");
        });
      }

      this.loginDisabled = false;
      this.navigateToApp();
    }, err => {
      this.loginDisabled = false;
      if (err.status === 401) {
        if (err._body) {
          const body = JSON.parse(err._body);
          if (body.loginStatus === this.constants.loginStatus.loggedOut)
            this.loginErrorMsg = 'DUPLICATE_LOGIN';
          else if (body.loginStatus === this.constants.loginStatus.blocked)
            this.loginErrorMsg = 'ACCOUNT_BLOCKED';
        } else {
          this.loginConnectionError = true;
        }
      }

      this.authenticationService.removeUserData();
    });
  }

  private captchaClicked() {
    console.log("captcha clicked");
  }
}
