import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MenuMode } from '../navigation/menu/menu.component';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  @Output() onChange = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.setBreakpoints(10, 1);
    this.observeBreakpoints();
  }

  public setBreakpoints(appsNumber: number, additionalLinksNumber: number): void {
    const large = 259 + (appsNumber + additionalLinksNumber) * 50;
    const medium = 259 + Math.ceil((appsNumber + additionalLinksNumber) / 2) * 55;

    Breakpoints.Small = '(max-height:' + medium + 'px)';
    Breakpoints.Medium = '(min-height:' + medium + 'px) and (max-height:' + large + 'px)';
    Breakpoints.Large = '(min-height:' + large + 'px)';

    Breakpoints.XLarge = '(min-width:1367px)';
    this.observeBreakpoints();
  }

  public observeBreakpoints() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .subscribe(() => this.onChange.next());
  }

  private observeScreenWidth() {
    this.breakpointObserver
      .observe([Breakpoints.XLarge])
      .subscribe(() => this.onChange.next());
  }

  public get sizes() {
    return {
      isSmallScreen: this.breakpointObserver.isMatched(Breakpoints.Small),
      isMediumScreen: this.breakpointObserver.isMatched(Breakpoints.Medium),
      isLargeScreen: this.breakpointObserver.isMatched(Breakpoints.Large),
      isXLargeScreen: this.breakpointObserver.isMatched(Breakpoints.XLarge)
    };
  }

  public getMenuMode(): MenuMode {
    if (this.sizes.isSmallScreen) {
      return MenuMode.Pop;
    } else if (this.sizes.isMediumScreen) {
      return MenuMode.Grid;
    } else {
      return MenuMode.List;
    }
  }
}
