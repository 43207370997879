import { Injectable, Inject } from '@angular/core';
// import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
// 
import { HttpClient, HttpHeaders, HttpResponse, HttpClientModule } from '@angular/common/http';

@Injectable()
export class ServerService {

  constructor(
    private httpClient: HttpClient,
    @Inject('Constants') private constants: any) {
  }

  get(url, header): Observable<any> {
    return this.httpClient.get(url, this.getHeader(header, true));
  }

  post(url, body, header): Observable<any> {
    return this.httpClient.post(url, body, this.getHeader(header, true));
  }

  put(url, body, header): Observable<any> {
    return this.httpClient.put(url, body, this.getHeader(header, true));
  }

  patch(url, body, header): Observable<any> {
    return this.httpClient.patch(url, body, this.getHeader(header, true));
  }

  rawGet(url, header): Observable<any> {
    return this.httpClient.get(url, this.getHeader(header));
  }

  rawPost(url, body, header): Observable<any> {
    return this.httpClient.post(url, body, this.getHeader(header));
  }

  rawPatch(url, body, header): Observable<any> {
    return this.httpClient.patch(url, body, this.getHeader(header));
  }

  getCookieFromQlik(url, header) : Observable<HttpResponse <any>> {
    const headers = this.getHeader(header, true);
    headers['observe'] = 'response';
    headers['responseType'] = 'text';
    headers['withCredentials'] = true; 
    return this.httpClient.get<HttpResponse<any>>(url, headers);
  }

  private getHeader(headers, tokenNeeded = false) {
    let httpHeaders = new HttpHeaders();

    if (!headers['Content-Type'])
      httpHeaders = httpHeaders.append('Content-Type', 'application/json');

    if (tokenNeeded) {
      httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
    }

    Object.keys(headers).forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });

    // const options = new RequestOptions({ headers: headers });
    return { headers: httpHeaders };
  }
}