import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
/**
 * @title Injecting data when opening a dialog
 */
@Component({
  selector: 'dialog-support-contact-us',
  templateUrl: 'support-contact-dialog.component.html',
  styleUrls: ['support-contact-dialog.component.less']
})

export class DialogSupportContact {
  public supportContactMessage: any;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: { supportContactMessage: any }) {
    this.supportContactMessage = this.data.supportContactMessage;
  }
}