import { AppCode } from "./AppCode";

export interface AppOption {
    title: string;
    code: AppCode;
    selected: boolean;
    iconPath: string;
    url: string;
}

export function getPathColorful(path: string): string {
    return path.slice(0, -10) + '.svg';
}