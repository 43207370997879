import { Routes } from '@angular/router';
import { ApContainerComponent } from '../ap-container/ap-container.component';
import { DocumentContainerComponent } from '../document-container/document-container.component';
import { HomeContainerComponent } from '../home-container/home-container.component';
import { LoginContainerComponent } from '../login-container/login-container.component';
import { LogoutContainerComponent } from '../logout-container/logout-container.component';
import { MainContainerComponent } from '../main-container/main-container.component';
import { PlanogramContainerComponent } from '../planogram-container/planogram-container.component';
import { PreferencesContainerComponent } from '../preferences-container/preferences-container.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { AppGuardService } from '../services/app-guard.service';
import { UserManagementContainerComponent } from '../user-management-container/user-management-container.component';
import { DashboardComponent } from '../navigation/dashboard/dashboard.component';
import { VersionComponent } from '../version/version/version.component';

export const routes: Routes = [
    { path: '', redirectTo: 'main', pathMatch: 'full', canActivate: [AuthGuardService] },
    { path: 'login', component: LoginContainerComponent },
    { path: 'logout', component: LogoutContainerComponent },
    { path: 'logout/:option', component: LogoutContainerComponent },
    { path: 'login/:option', component: LoginContainerComponent },
    { path: 'planogram/:file-key', component: PlanogramContainerComponent, canActivate: [AuthGuardService] },
    { path: 'version', component: VersionComponent },

    {
        path: 'main', component: MainContainerComponent, canActivate: [AuthGuardService],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'document', component: DocumentContainerComponent },
            { path: 'home', component: HomeContainerComponent, canActivate: [AppGuardService], runGuardsAndResolvers: 'always' },
            { path: 'user-management', component: UserManagementContainerComponent },
            // { path: 'ap', component: ApContainerComponent, canActivate: [AuthGuardService] },
            // { path: 'ap/:option', component: ApContainerComponent, canActivate: [AuthGuardService] },
            { path: 'price', loadChildren: 'pricing-fe/src/app/pricing/pricing.module#PricingModule', canActivate: [AppGuardService], runGuardsAndResolvers: 'always'},
            { path: 'promo', loadChildren: 'promo-fe/src/app/promo/promo.module#PromoModule', canActivate: [AppGuardService] , runGuardsAndResolvers: 'always'},
            { path: 'insights-portal', loadChildren: 'insights-portal-fe/src/app/insights/insights.module#InsightsModule', canActivate: [AppGuardService], runGuardsAndResolvers: 'always' },
            { path: 'assortment', loadChildren: 'assortment-fe/src/app/assortment/assortment.module#AssortmentModule', canActivate: [AppGuardService],  runGuardsAndResolvers: 'always' },
            { path: 'data-portal', loadChildren: 'data-portal-fe/src/app/data-portal/data-portal.module#DataPortalModule', canActivate: [AppGuardService] , runGuardsAndResolvers: 'always'},
            { path: 'preferences', component: PreferencesContainerComponent },
            { path: 'dashboard', component: DashboardComponent }
        ]
    },
    { path: '**', redirectTo: 'main', canActivate: [AuthGuardService] }
];
