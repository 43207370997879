import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.less']
})
export class RibbonComponent implements OnInit {

  @Input() hide = false;
  @Input() text = 'QA';

  constructor() { }

  ngOnInit() { }
}
