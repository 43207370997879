import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { UserService } from '../services/user.service';
import { AppConfigService } from '../services/app-config.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { UtilsService } from '../utilities/utils';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-logout-container',
  templateUrl: './logout-container.component.html',
  styleUrls: ['./logout-container.component.less']
})
export class LogoutContainerComponent implements OnInit {

  // @ViewChild('logoutPricingFrame') iframePricing: any;
  // @ViewChild('logoutAAFrame') iframeAA: any;
  showSpinner = true;
  showPricing = false;
  showAA = false;
  activeApps = new Set();
  pricingGoDomain: string;
  aaGoDomain: string;

  constructor(private authenticationService: AuthenticateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private utils: UtilsService,
    private logger: NGXLogger,
    @Inject('Constants') private constants: any,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    if (!this.authenticationService.isAuthenticated()) {
      this.authenticationService.removeUserData();
      this.logger.debug('[GO] User is not authenticated');
      this.goToLogin();
      return;
    }

    // in case logout freezed which is likely to be caused by iframe loading errors.
    setTimeout(() => {
      this.authenticationService.removeUserData();
      this.goToLogin();
    }, 10000);

    this.appConfig.init(() => {
      this.pricingGoDomain = this.appConfig.get().webConfig.pricingDomain + '/go';
      this.aaGoDomain = this.appConfig.get().webConfig.aaDomain + '/dashboard/go';

      if (this.utils.checkPriceAccess()) {
        this.showPricing = true;
        this.activeApps.add(this.pricingGoDomain);
        // this.onIframeLoad(this.pricingGoDomain, 0);
      }

      if (this.utils.checkAAAccess() || this.utils.checkCustomProductListAccess()) {
        this.showAA = true;
        this.activeApps.add(this.aaGoDomain);
        // this.onIframeLoad(this.aaGoDomain, this.showPricing ? 1 : 0);
      }

      const checkApps = setInterval(() => {
        if (this.activeApps.size === 0) {
          this.authenticationService.removeUserData();
          this.goToLogin();
          clearInterval(checkApps);          
        }
      }, 100);
    }, (error) => {
      this.logger.error('[GO] Error initializing web config', error);
      this.authenticationService.removeUserData();
      this.goToLogin();
    });
  }

  private appLoad() {
    if (this.showPricing)
      this.onIframeLoad(this.pricingGoDomain, 0);

    if (this.showAA)
      this.onIframeLoad(this.aaGoDomain, this.showPricing ? 1 : 0);
  }

  private onIframeLoad(appName, index) {
    setInterval(() => {
      if (this.activeApps.has(appName)) {
        this.logger.debug('[GO] Posting logout message', appName, index);
        const iframe = window.frames[index];
        const msg = JSON.stringify({ key: 'logout', data: '' });
        iframe.postMessage(msg, appName);
        this.activeApps.delete(appName);
      }
    }, 2000);
  }

  onLoadError() {
    this.authenticationService.removeUserData();
    this.goToLogin();
  }

  private goToLogin() {
    this.showSpinner = false;
    const url = window.location.href;
    const newUrl = url.replace('/logout', '/login');
    window.location.href = newUrl;
  }
}
